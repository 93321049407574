import React from "react";
import RightSidebar from "./RightSidebar";

const PageLayouts = ({ children }) => {
  return (
    <div className="page-content">
      <div className="container g-0">
        <div className="row g-0">
          <div className="col-lg-8 col-md-12">
            {children}
          </div>
          <div className="col-lg-4 col-md-12">
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLayouts;
