import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Editor } from "primereact/editor";
import { toast } from "react-hot-toast";
import {
  useGetUserDetailsQuery,
  useUpdateUserMutation,
} from "../../../redux/api/userApi";
import cities from "../../../../constants/cities.json";

const EditUser = () => {
  const [update, { isSuccess, error, isLoading: updateLoading }] =
    useUpdateUserMutation();

  const { id } = useParams();
  const { data: users, isLoading } = useGetUserDetailsQuery(id);

  const [data, setData] = useState({});
  const [selectedCity, setSelectedCity] = useState("");

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const submitHandler = () => {
    update(data);
  };
  const onChangeCity = (e) => {
    setData({ ...data, city: e.target.value });
    console.log(e.target.value);
    const filteredData = cities?.data?.filter(
      (il) => il.il_adi === e.target.value
    );
    setSelectedCity(filteredData[0]);
  };
  console.log(users?.user);

  useEffect(() => {
    if (users) {
      setData({
        firstname: users?.user?.firstname,
        lastname: users?.user?.lastname,
        email: users?.user?.email,
        role: users?.user?.role,
        phoneNumber: users?.user?.phoneNumber,
        city: users?.user?.city,
        district: users?.user?.district,
        address: users?.user?.address,
        job: users?.user?.job,
        bloodyType: users?.user?.bloodyType,
        birthdate: users?.user?.birthdate,
        id: users?.user?._id,
      });
      setSelectedCity(users?.user?.district);
    }
    if (selectedCity !== "") {
      const filteredData = cities?.data?.filter(
        (il) =>
          il.il_adi === data?.city
      );
      setSelectedCity(filteredData[0]);
    }
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Kullanıcı Güncellendi.");
    }
  }, [users, error, isSuccess]);

  if (isLoading) {
    return <h1>YÜKLENİYOR</h1>;
  }

  return (
    <div className="container mt-5">
      <div className="row  shadow-sm border rounded-2">
        <h1>Kullanıcı Güncelle</h1>
        <div className="col">
          <div className="mb-3">
            <label htmlFor="firstname" className="form-label">
              Ad:
            </label>
            <input
              type="text"
              className="form-control"
              id="firstname"
              placeholder="Adınızı girin"
              value={data.firstname}
              onChange={(e) => setData({ ...data, firstname: e.target.value })}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="lastname" className="form-label">
              Soyad:
            </label>
            <input
              type="text"
              className="form-control"
              id="lastname"
              placeholder="Soyadınızı girin"
              value={data.lastname}
              onChange={(e) => setData({ ...data, lastname: e.target.value })}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              E-Posta:
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="E-Postanızı girin"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="phoneNumber" className="form-label">
              Telefon Numarası:
            </label>
            <input
              type="tel"
              className="form-control"
              id="phoneNumber"
              placeholder="Numaranızı giriniz"
              value={data.phoneNumber}
              onChange={(e) =>
                setData({ ...data, phoneNumber: e.target.value })
              }
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="job" className="form-label">
              Mezuniyet Mesleği:
            </label>
            <input
              type="text"
              className="form-control"
              id="job"
              placeholder="Mesleğinizi girin"
              value={data.job}
              onChange={(e) => setData({ ...data, job: e.target.value })}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="birthdate" className="form-label">
              Doğum Tarihi
            </label>
            <input
              type="date"
              className="form-control"
              id="birthdate"
              value={
                new Date(users?.user?.birthdate)?.toISOString()?.split("T")[0]
              }
              onChange={(e) => setData({ ...data, birthdate: e.target.value })}
              required
            />
          </div>
          <div className="mb-3">
            <span className="">Kan Grubu</span>
            <select
              name="bloodyType"
              value={data.bloodyType}
              onChange={(e) => setData({ ...data, bloodyType: e.target.value })}
              className="form-control"
            >
              <option selected>Bir kan grubu seç</option>
              <option value="0-">0-</option>
              <option value="0+">0+</option>
              <option value="A-">A-</option>
              <option value="A+">A+</option>
              <option value="B-">B-</option>
              <option value="B+">B+</option>
              <option value="AB-">AB-</option>
              <option value="AB+">AB+</option>
            </select>
          </div>
          <div className="mb-3">
            <span className="">İl</span>
            <select
              name="city"
              value={data.city}
              onChange={(e) => onChangeCity(e)}
              className="form-control"
            >
              <option selected>Bir şehir seç</option>
              {cities?.data?.map((city) => (
                <option value={city?.il_adi}>
                  {city?.il_adi}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <span className="">İlçe</span>
            <select
              name="district"
              value={data?.district}
              onChange={(e) => setData({ ...data, district: e.target.value })}
              className="form-control"
            >
              <option selected>Bir ilçe seç</option>
              {selectedCity?.ilceler?.map((d) => (
                <option value={d?.ilce_adi}>
                  {d?.ilce_adi}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-3">
            <span className="">Açık Adres</span>
            <textarea
              name="address"
              value={data.address}
              placeholder="Adres giriniz"
              onChange={(e) => setData({ ...data, address: e.target.value })}
              className="form-control"
            />
          </div>
          <div className="mb-5">
            <span className="">Roller</span>
            <select
              name="role"
              value={data.role}
              onChange={onChange}
              className="form-control"
            >
              <option selected>Bir rol seç</option>
              <option value="admin">Admin</option>
              <option value="user">Kullanıcı</option>
            </select>
          </div>

          <div className="mb-3 d-flex justify-content-end">
            <button
              className="btn btn-primary"
              disabled={updateLoading}
              onClick={() => submitHandler()}
            >
              {updateLoading ? "Güncelleniyor..." : "Güncelle"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
