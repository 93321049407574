import React, { useEffect, useState } from "react";
import { FaSquarePlus, FaUsers } from "react-icons/fa6";
import { IoNewspaperOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../layouts/Loader";
import toast from "react-hot-toast";

const AdminLayouts = ({ children }) => {
  const [activeBar, setActiveBar] = useState("");
  const navigate = useNavigate();
  const sidebarItems = [
    {
      url: "/admin/haberleri-getir",
      text: "Haberler",
      icon: <IoNewspaperOutline size={38} />,
    },
    {
      url: "/admin/haber-olustur",
      text: "Haber Oluştur",
      icon: <FaSquarePlus size={38} />,
    },
    {
      url: "/admin/kullanicilari-getir",
      text: "Kullanıcılar",
      icon: <FaUsers size={38} />,
    }
  ];

  useEffect(() => {
    if (window) {
      setActiveBar(window.location.pathname);
    }
  }, [activeBar]);

  const { user, isAuthentication, loading } = useSelector(
    (state) => state.auth
  );
  if (loading) return <Loader />;
  if (isAuthentication) navigate("/giris");
  if (user?.data?.role !== "admin") {
    toast.error("Yetki dışı işlem");
    navigate("/giris");
  }

  return (
    <div className="container mt-5">
      <div className="row mb-5">
        <div
          className="d-flex flex-column justify-content-center align-items-center "
          style={{ maxHeight: "200px", overflow: "auto", maxWidth: "100%" }}
        >
          <div className="border rounded-1" style={{ maxWidth: "400px", width: "100%" }}>
            {sidebarItems?.map((item) => (
              <div
                className="d-flex gap-3 align-items-center p-2 "
                style={{
                  width: "100%",
                  backgroundColor: `${activeBar == item?.url && "#39608B"}`,
                  color: `${activeBar == item?.url && "white"}`,
                }}
              >
                {item.icon}
                <a
                  href={item.url}
                  className=""
                  style={{ color: `${activeBar == item?.url && "white"}` }}
                >
                  <span>{item.text}</span>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default AdminLayouts;
