import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from '../../layouts/MetaData'

const Vizyon = () => {
  return (
    <PageLayouts>
      <MetaData title={"Vizyon"} />
      <div id="content-dinamik-title">
        <div id="content-title-type">VİZYON</div>
      </div>
      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="baskanlik-content">
          <p>
            <b>Turan Ocakları Eğitim ve Kültür Vakfı "VİZYONUMUZ"</b>
          </p>
          <p>
            - Milli bir vizyon etrafında şekillenmiş, köklü bir kurumsal kimliğe
            sahiptir. Vakfımız, Türk milletinin tarihine, kültürüne ve milli
            değerlerine derin bir bağlılıkla eğitim ve kültür alanında liderlik
            yapmayı amaçlar.
          </p>
          <p>
            Misyonumuz, genç nesilleri vatansever, bilinçli ve liderlik
            vasıflarına sahip bireyler olarak yetiştirmek ve Türk kültürünü daha
            geniş kitlelere ulaştırmaktır. Bu doğrultuda, eğitim bursları
            sağlayarak akademik başarıyı teşvik ederken, aynı zamanda milli
            değerlere vurgu yapan özel projelerle gençlerin karakter gelişimine
            katkıda bulunmayı hedefleriz.
          </p>
          <p>
            Vakfımızın programları ve etkinlikleri, Türk milletinin birlik ve
            beraberlik ruhunu pekiştirmeyi amaçlar. Milli duruşumuzla toplum
            bilincini güçlendirirken, aynı zamanda Türk kültürünü koruyarak ve
            geliştirerek gelecek kuşaklara aktarmayı kendisine ilke edinir. Türk
            gençliğini milli değerlerle donatarak, onları liderlik vasıflarına
            sahip, sorumluluk bilinciyle hareket eden bireyler olarak
            yetiştirmeyi görev ediniriz.
          </p>
        </div>
      </div>
    </PageLayouts>
  );
};

export default Vizyon;
