import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from "../../layouts/MetaData";

const Mesajlar = () => {
  return (
    <PageLayouts>
      <MetaData title={"Mesajlar"} />
      <div id="content-dinamik-title">
        <div id="content-title-type" style={{ width: "60%" }}>
          Turan Ocakları Eğitim ve Kültür Vakfı
        </div>
        <div className="baskanlik-header">MESAJLAR</div>
      </div>
      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="baskanlik-content">
          <div className="baskanlik-anasayfa-alt-haber">
            <div className="row">
              <div className="col-md-6" style={{ marginBottom: "20px" }}>
                <a href="/Haber/Detay?Id=27b2d485-7c87-42ae-b14d-018eb2e46028">
                  <div
                    className="arh ImageLazyLoader"
                    data-resim-yolu=""
                    style={{
                      backgroundImage:
                        "url('/img/turan-ocaklari-basin-1-1_4.png')",
                    }}
                  ></div>

                  <div className="art">17 Ocak 2024</div>
                  <div className="arb">
                    Terörün Gölgesinde Yıllardır Devam Eden Bir Mücadele!
                  </div>
                  <div className="ard">
                    Bu satırları yazarken, yüreğimde hissettiğim derin duyguları
                    sizinle paylaşmanın vakti geldiğini düşündüm. Türkiye'mizin,
                    yıllardır terör örgütleri PKK ve YPG tarafından hedef
                    alınmasının ardında yatan gerçekleri konuşmak, belki de
                    içimizde biriken hisleri dökmenin tam zamanı.
                  </div>
                </a>
              </div>
              <div className="col-md-6" style={{ marginBottom: "20px" }}>
                <a href="/Haber/Detay?Id=71099ea5-cc53-4663-8ead-018eaf0c6cce">
                  <div
                    className="arh ImageLazyLoader"
                    data-resim-yolu=""
                    style={{
                      backgroundImage:
                        "url('/img/turan-ocaklari-basin-1-1_4.png')",
                    }}
                  ></div>

                  <div className="art">11 Ocak 2024</div>
                  <div className="arb">Regaip Kandili'miz Kutlu Olsun</div>
                  <div className="ard">
                    Bu mübarek Regaip Kandili'nde yüreğimiz, Filistin halkına
                    yönelik duyduğumuz derin endişe ve dayanışma ile
                    çarpmaktadır. Tarihimiz boyunca eşsiz kandiller, birlik ve
                    beraberliğimizi pekiştirmenin yanı sıra, mazlumların sesine
                    kulak verme ve yardım elini uzatma fırsatlarını da
                    içermiştir.
                  </div>
                </a>
              </div>
              <div className="col-md-6" style={{ marginBottom: "20px" }}>
                <a href="/Haber/Detay?Id=c30a6eb5-872c-4a31-9b43-018eae0caafb">
                  <div
                    className="arh ImageLazyLoader"
                    data-resim-yolu=""
                    style={{
                      backgroundImage:
                        "url('/img/turan-ocaklari-basin-1-1_4.png')",
                    }}
                  ></div>

                  <div className="art">10 Ocak 2024</div>
                  <div className="arb">
                    ÇAĞIN MANEVİ HASTALIKLARINA TEK ÇARE; Turan Ocaklarında!
                  </div>
                  <div className="ard">
                    Yaşadığımız çağ, sadece maddi değil, manevi olarak da birçok
                    zorluğa tanıklık ediyor. Toplumsal birliğimizi tehdit eden
                    hastalıklar, adeta ruhumuzu sarmalıyor. Bu manevi yozlaşma,
                    dışarıdan bakıldığında belki de en fazla "Turan Ocakları"
                    gibi milli, manevi ve eğitim odaklı oluşumların önemini
                    ortaya koyuyor.
                  </div>
                </a>
              </div>
              <div className="col-md-6" style={{ marginBottom: "20px" }}>
                <a href="/Haber/Detay?Id=48458fc0-31a6-4fba-8a1a-018eadb1e2fc">
                  <div
                    className="arh ImageLazyLoader"
                    data-resim-yolu=""
                    style={{
                      backgroundImage:
                        "url('/img/turan-ocaklari-basin-1-1_4.png')",
                    }}
                  ></div>

                  <div className="art">10 Ocak 2024</div>
                  <div className="arb">
                    Turan Ocakları Eğitim ve Kültür Vakfı, Türk Milli
                    Değerlerine Bağlılığını Yenilenen Web Sitesi ile
                    Güçlendiriyor
                  </div>
                  <div className="ard">
                    Turan Ocakları Eğitim ve Kültür Vakfı, Türk milletinin
                    zengin tarihine, kültürüne ve milli değerlerine olan derin
                    sevgi ve bağlılıkla öne çıkan bir sivil toplum kuruluşudur.
                    Vakıf, genç nesillerin milli kimlikleriyle bütünleşmelerine,
                    liderlik vasıflarını geliştirmelerine ve vatanlarına daha
                    fazla hizmet etmelerine odaklanarak eğitim ve kültür
                    alanlarında önemli bir rol üstlenmektedir.
                  </div>
                </a>
              </div>

              <div className="col-md-6" style={{ marginBottom: "20px" }}>
                <a href="/Haber/Detay?Id=48458fc0-31a6-4fba-8a1a-018eadb1e2fc">
                  <div
                    className="arh ImageLazyLoader"
                    data-resim-yolu=""
                    style={{
                      backgroundImage:
                        "url('/img/turan-ocaklari-basin-1-1_4.png')",
                    }}
                  ></div>

                  <div className="art">10 Ocak 2024</div>
                  <div className="arb">Gençlere Yönelik Tavsiyeler</div>
                  <div className="ard">
                    Sevgili Gençler, <br />
                    Hayat yolculuğunda ilerlerken, başarı ve mutluluğa giden
                    yolda rehberlik edebilecek bazı değerli tavsiyeleri
                    paylaşmak istiyorum. Bu tavsiyeler, hem kişisel hem de
                    profesyonel hayatınızda sizi daha başarılı kılacak
                    prensipleri içeriyor.
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  );
};

export default Mesajlar;
