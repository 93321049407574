import React, { useEffect, useState } from "react";
import { useLoginMutation } from "../../redux/api/authApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({ email: "", password: "" });
  const { email, password } = data;

  const [login, { error, isSuccess, data: user }] = useLoginMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Hoş geldin ${user?.user?.firstname}`);
      navigate("/");
    }
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [error, isSuccess]);

  return (
    <div className="d-flex justify-content-center align-items-center bg-light" style={{height: "70vh"}}>
      <div
        className="card p-4 shadow-sm"
        style={{ width: "100%", maxWidth: "400px" }}
      >
        <h2 className="text-center mb-4">Giriş Yap</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">
              E-Posta:
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              placeholder="E-posta girin"
              value={email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Şifre:
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Şifrenizi girin"
              value={password}
              onChange={(e) => setData({ ...data, password: e.target.value })}
              required
            />
          </div>
          <button type="submit" className="btn w-100 text-white" style={{backgroundColor: "#39608B"}}>
            Giriş Yap
          </button>
        </form>
        <div className="mt-3 text-center">
          <a href="/kayit" className="text-decoration-none" style={{color: "#39608B"}}>Hesabın Mı Yok ?</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
