import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from '../../layouts/MetaData'

const Tuzuk = () => {
  return (
    <PageLayouts>
      <MetaData title={"Tüzük"} />
      <div id="content-dinamik-title">
        <div id="content-title-type">TÜZÜK</div>
      </div>
      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="baskanlik-content">
          <p>
            <b>TURAN OCAKLARI EĞİTİM VE KÜLTÜR VAKFI TÜZÜĞÜ</b>
          </p>
          <br />
          <p>
            <b>Vakıfın Adı ve Merkezi</b>
          </p>
          <p>
            Madde 1- Vakfın Adı: “TURAN OCAKLARI EĞİTİM VE KÜLTÜR VAKFI”
            dir.Vakıf merkezi Ankara'dır.
          </p>
          <br />
          <p>Vakıf, yurt içinde ve yurt dışında şube açabilir.</p>
          <p>
            Vakfın Amacı ve Bu Amacı Gerçekleştirmek İçin Vakıfca Sürdürülecek
            Çalışma Konuları ve Biçimleri İle Faaliyet Alanı
          </p>
          <p>
            <b>Madde 2-Vakıf,</b> Tüm Türk Dünyası’na, Dünya Türkleri’ne yönelik
            ortak kültürün, ortak tarihin ve milli şuurun yüceltilmesi; bu
            bilinç ile Türk yurdu olan her coğrafyada, her ülkede kültürel
            faaliyetler de bulunmak, bölgesel ve geneli kapsayan projeleri
            hayata geçirmek,
          </p>
          <p>
            Devletin ve milletin birlik beraberliğini ve bekasını düstur edinen
            Turan kavramının tanımı olan “Millet için Devlet anlayışından yola
            çıkarak İslam kavramını açıklamak, anlatmak yaygınlaştırmak,
            kavratmak ve yaşatmak, bu amaç için gençleri bilinçlendirmek eğitmek
            ve yetişmek için eğitimler ve faaliyetler yapmak, aynı faaliyetlerde
            bulunan kamu ve özel kurum kuruluşlarla da koordine içinde hareket
            ederek maddi ve manevi desteklerde bulunmak, Türk-İslam birliği
            hareketlerinin geliştirilmesini, yeniden canlandırılmasını sağlamak,
            tasavvufi etkinlikleri yapmak, vakfın amaç edindiği ilke
            doğrultusunda yapmayı hedeflediği yardım, eğitim ve faaliyetler,
            bölge, ülke ve inanç ayrımı yapılmaksızın herkesin maddi manevi
            yardımına koşmak amacı ile kurulmuştur.
          </p>
          <p>
            <b>Vakıfca Sürdürülecek Çalışma Konuları ve Biçimleri</b>
          </p>
          <p>
            1-Faaliyetlerinin etkinleştirilmesi ve geliştirilmesi için
            araştırmalar yapmak,
          </p>
          <p>
            x2-Kurs, seminer, konferans ve panel gibi eğitim çalışmaları
            düzenlemek,
          </p>
          <p>
            x3-Amacın gerçekleştirilmesi için gerekli olan her türlü bilgi,
            belge, doküman ve yayınları temin etmek, dokümantasyon merkezi
            oluşturmak, çalışmalarını duyurmak için amaçları doğrultusunda
            gazete, dergi, kitap ve bülten gibi yayınlar çıkarmak,
          </p>
          <p>
            x4-Amacın gerçekleştirilmesi için sağlıklı bir çalışma ortamını
            sağlamak, her türlü teknik araç ve gereci, demirbaş ve kırtasiye
            malzemelerini temin etmek,
          </p>
          <p>
            x5-Gerekli izinler alınmak şartıyla yardım toplama faaliyetlerinde
            bulunmak ve yurt içinden ve yurt dışından bağış kabul etmek,
          </p>
          <p>
            x6-Tüzük amaçlarının gerçekleştirilmesi için ihtiyaç duyduğu
            gelirleri temin etmek amacıyla iktisadi, ticari ve sanayi işletmeler
            kurmak ve işletmek,
          </p>
          <p>
            x7-Üyelerinin yararlanmaları ve boş zamanlarını
            değerlendirebilmeleri için lokal açmak, sosyal ve kültürel tesisler
            kurmak ve bunları tefriş etmek,
          </p>
          <p>
            {" "}
            8-Üyeleri arasında beşeri münasebetlerin geliştirilmesi ve devam
            ettirilmesi için yemekli toplantılar, konser, balo, tiyatro, sergi,
            spor, gezi ve eğlenceli etkinlikler vb. düzenlemek veya üyelerinin
            bu tür etkinliklerden yararlanmalarını sağlamak,
          </p>
          <p>
            9-Vakıf faaliyetleri için ihtiyaç duyulan taşınır, taşınmaz mal
            satın almak, satmak, kiralamak, kiraya vermek ve taşınmazlar
            üzerinde ayni hak tesis etmek,
          </p>
          <p>
            10-Amacın gerçekleştirilmesi için gerek görülmesi durumunda yurt
            içinde ve yurt dışında vakıf kurmak, federasyon kurmak veya kurulu
            bir federasyona katılmak, gerekli izin alınarak vakıfların
            kurabileceği tesisleri kurmak,
          </p>
          <p>
            11-Uluslararası faaliyette bulunmak, yurt dışındaki vakıf veya
            kuruluşlara üye olmak ve bu kuruluşlarla ortak çalışmalar yapmak
            veya yardımlaşmak,
          </p>
          <p>
            12-Amacın gerçekleştirilmesi için gerek görülmesi halinde, 5072
            sayılı Dernek ve Vakıfların Kamu Kurum ve Kuruluşları ile
            İlişkilerine Dair Kanun hükümleri saklı kalmak üzere, kamu kurum ve
            kuruluşları ile görev alanlarına giren konularda ortak projeler
            yürütmek,
          </p>
          <p>
            13-Vakıf üyelerinin yiyecek, giyecek gibi zaruri ihtiyaç maddelerini
            ve diğer mal ve hizmetlerle kısa vadeli kredi ihtiyaçlarını
            karşılamak amacıyla sandık kurmak,
          </p>
          <p>14-Gerekli görülen yerlerde şube ve temsilcilikler açmak,</p>
          <p>
            15-Vakfın amacı ile ilgisi bulunan ve kanunlarla yasaklanmayan
            alanlarda, diğer derneklerle veya vakıf, sendika ve benzeri sivil
            toplum kuruluşlarıyla ortak bir amacı gerçekleştirmek için
            plâtformlar oluşturmak,
          </p>
          <p>
            16-Amacın gerçekleştirilmesi için ihtiyaç duyulan ve kanunların
            yasaklamadığı her türlü faaliyette bulunmak,
          </p>
          <p>
            17- İnsan hak ve özgürlüğüne aykırı yapılan her türlü olumsuzluğa
            karşı hukuksal mücadelede maddi ve manevi destek sağlamak,
          </p>
          <p>
            18- İhtiyaç sahibi öğrencilere burs vererek eğitimlerine destek
            sağlamak,
          </p>
          <p>
            19- Doğal afetlerden etkilenerek mağdur olan insanlara, giyim, gıda,
            yakacak, barınma ve sağlık alanında destek vermek,
          </p>
          <p>
            20- Engelli bireylere her türlü maddi ve manevi desteği vererek
            onların topluma kazandırılmasını sağlamak,
          </p>
          <p>
            21- ihtiyaç sahibi olan hastaların tedavileri için gerekli olan tüm
            desteği sunmak için sağlık alanında hizmet veren tüm kurum ve
            kuruluşlarla ortak çalışmalar yaparak hizmette bulunmak,
          </p>
          <p>
            22- Çevrenin doğal yapısını korumaya yönelik çalışmalar yapmak ve bu
            konuda ilgili kuruluşlara destek vermek,
          </p>
          <p>
            23- İnsan hak ve hürriyetlerine yönelik her türlü olumsuzluğa ve
            hukuksuzluğa karşı hak ve özgürlük bilinci oluşturmak,
          </p>
          <p>
            24- Fikir, ilim, tarih, kültür, din, ahlak, iktisat, sanat
            alanlarında toplumumuzun gelişmesine katkı sağlamak,
          </p>
          <p>
            25- Toplumda yardımlaşma ve dayanışmanın tesisi ile birlikte
            kültürel ve ahlaki birliktelikleri sağlamak ve toplumun
            sosyo-ekonomik düzeyinin gelişmesine katkıda bulunmak,
          </p>
          <p>
            26- Dünya Türkleri’ne yönelik ortak kültürün, ortak tarihin ve milli
            şuurun yüceltilmesi amacı ile eğitimler gerçekleştirmek,
          </p>

          <p>
            <b>Vakfın Faaliyet Alanı:</b>
          </p>
          <p>
            Vakıf, sosyal alanda yurt içinde ve yurt dışında faaliyet gösterir.
          </p>
          <p>
            <b>Üye Olma Hakkı ve Üyelik İşlemleri</b>
          </p>
          <p>
            <b>Madde 3-</b> Fiil ehliyetine sahip bulunan ve vakfın amaç ve
            ilkelerini benimseyerek bu doğrultuda çalışmayı kabul eden ve
            Mevzuatın öngördüğü koşullarını taşıyan her gerçek ve tüzel kişi bu
            vakfa üye olma hakkına sahiptir. Ancak, yabancı gerçek kişilerin üye
            olabilmesi için Türkiye’de yerleşme hakkına sahip olması da gerekir.
            Onursal üyelik için bu koşul aranmaz. Vakıf başkanlığına yazılı
            olarak yapılacak üyelik başvurusu, vakıf yönetim kurulunca en çok
            otuz gün içinde üyeliğe kabul veya isteğin reddi şeklinde karara
            bağlanır ve sonuç yazıyla başvuru sahibine bildirilir. Başvurusu
            kabul edilen üye, bu amaçla tutulacak deftere kaydedilir.
          </p>
          <p>
            Vakfın asıl üyeleri, vakfın kurucuları ile müracaatları üzerine
            yönetim kurulunca üyeliğe kabul edilen kişilerdir. Vakfa maddi ve
            manevi bakımdan önemli destek sağlamış bulunanlar yönetim kurulu
            kararı ile onursal üye olarak kabul edilebilir.
          </p>
          <p>
            Vakfın şube sayısı üçten fazla olduğunda vakıf merkezinde kayıtlı
            bulunanların üyelik kayıtları şubelere aktarılır. Yeni üyelik
            müracaatları şubelere yapılır. Üyeliğe kabul ve üyelikten silinme
            işlemleri şube yönetim kurulları tarafından yapılır ve en çok otuz
            gün içinde bir yazıyla Genel Merkeze bildirilir.
          </p>
          <p>
            <b>Üyelikten Çıkma</b>
          </p>
          <p>
            <b>Madde 4- </b>Her üye yazılı olarak bildirmek kaydıyla, vakıftan
            çıkma hakkına sahiptir. Üyenin istifa dilekçesi yönetim kuruluna
            ulaştığı anda çıkış işlemleri sonuçlanmış sayılır. Üyelikten
            ayrılma, üyenin vakfa olan birikmiş borçlarını sona erdirmez.
          </p>

          <p>
            <b>Üyelikten Çıkarılma</b>
          </p>
          <p>
            <b>Madde 5-</b>Vakıf üyeliğinden çıkarılmayı gerektiren haller.
          </p>
          <p>1-Vakıf tüzüğüne aykırı davranışlarda bulunmak,</p>
          <p>2-Verilen görevlerden sürekli kaçınmak,</p>
          <p>
            3-Yazılı ikazlara rağmen üyelik aidatını altı ay içinde ödememek,
          </p>
          <p>4-Vakıf organlarınca verilen kararlara uymamak.</p>
          <p>5-Üye olma şartlarını kaybetmiş olmak,</p>
          <p>
            Yukarıda sayılan durumlardan birinin tespiti halinde yönetim kurulu
            kararı ile üyelikten çıkarılabilir.
            <br /> Vakıftan çıkan veya çıkarılanlar, üye kayıt defterinden
            silinir ve vakıf malvarlığında hak iddia edemez.
          </p>
          <p>
            <b>Vakıf Organları</b>
          </p>
          <p>
            <b>Madde 6-</b>Vakfın organları aşağıda gösterilmiştir.
          </p>
          <p>1-Genel kurul,</p>
          <p>2-Yönetim kurulu,</p>
          <p>3-Denetim kurulu,</p>
          <p>
            <b>
              Vakıf Genel Kurulunun Kuruluş Şekli, Toplanma Zamanı ve Çağrı ve
              Toplantı Usulü
            </b>
          </p>
          <p>
            <b>Madde 7-</b>Genel kurul, vakfın en yetkili karar organı olup;
            vakfa kayıtlı üyelerden oluşur. Vakıf şubesinin açılması durumunda
            ise şube sayısı üçe kadar genel merkez ve şubelerinde kayıtlı
            üyelerden; şube sayısı üçten fazla olması durumunda ise genel
            merkezdeki kayıtlı üyeler şubelere nakledilerek şubelerin genel
            kurullarında seçilen delegelerden oluşur.
          </p>
          <p>
            <b>Genel kurul;</b>
          </p>
          <p>1-Bu tüzükte belli edilen zamanda olağan,</p>
          <p>
            2-Yönetim veya denetim kurulunun gerekli gördüğü hallerde veya vakıf
            üyelerinden beşte birinin yazılı isteği üzerine otuz gün içinde
            olağanüstü toplanır.
          </p>
          <p>
            Olağan genel kurul, 3 yılda bir, Haziran ayı içerisinde, yönetim
            kurulunca belirlenecek gün yer ve saatte toplanır.
            <br />
            Genel kurul toplantıya yönetim kurulunca çağrılır.
          </p>
          <p>
            Yönetim kurulu, genel kurulu toplantıya çağırmazsa; üyelerden
            birinin başvurusu üzerine sulh hakimi, üç üyeyi genel kurulu
            toplantıya çağırmakla görevlendirir.
          </p>
          <p>
            <b>Çağrı Usulü</b>
          </p>
          <p>
            Yönetim kurulu, vakıf tüzüğüne göre genel kurula katılma hakkı
            bulunan üyelerin listesini düzenler. Genel kurula katılma hakkı
            bulunan üyeler, en az onbeş gün önceden, toplantının günü, saati,
            yeri ve gündemi en az bir gazetede veya vakfın internet sayfasında
            ilan edilmek, yazılı olarak bildirilmek, üyenin bildirdiği
            elektronik posta adresine ya da iletişim numarasına mesaj
            gönderilmek veya mahalli yayın araçları kullanılmak suretiyle
            toplantıya çağrılır. Bu çağrıda, çoğunluk sağlanamaması sebebiyle
            toplantı yapılamazsa, ikinci toplantının hangi gün, saat ve yerde
            yapılacağı da belirtilir. İlk toplantı ile ikinci toplantı
            arasındaki süre yedi günden az, altmış günden fazla olamaz.
            <br /> Toplantı, çoğunluk sağlanamaması sebebinin dışında başka bir
            nedenle geri bırakılırsa, bu durum geri bırakma sebepleri de
            belirtilmek suretiyle, ilk toplantı için yapılan çağrı usulüne uygun
            olarak üyelere duyurulur. İkinci toplantının geri bırakma tarihinden
            itibaren en geç altı ay içinde yapılması zorunludur. Üyeler ikinci
            toplantıya, birinci fıkrada belirtilen esaslara göre yeniden
            çağrılır.
          </p>
          <p>Genel kurul toplantısı bir defadan fazla geri bırakılamaz.</p>
          <p>
            <b>Toplantı Usulü</b>
          </p>
          <p>
            Genel kurul, katılma hakkı bulunan üyelerin salt çoğunluğunun, tüzük
            değişikliği ve vakfın feshi hallerinde ise üçte ikisinin katılımıyla
            toplanır; çoğunluğun sağlanamaması sebebiyle toplantının ertelenmesi
            durumunda ikinci toplantıda çoğunluk aranmaz. Ancak, bu toplantıya
            katılan üye sayısı, yönetim ve denetim kurulları üye tam sayısının
            iki katından az olamaz.
            <br /> Genel kurula katılma hakkı bulunan üyelerin listesi toplantı
            yerinde hazır bulundurulur. Toplantı yerine girecek üyelerin resmi
            makamlarca verilmiş kimlik belgeleri, yönetim kurulu üyeleri veya
            yönetim kurulunca görevlendirilecek görevliler tarafından kontrol
            edilir. Üyeler, yönetim kurulunca düzenlenen listedeki adları
            karşısına imza koyarak toplantı yerine girerler.
            <br /> Toplantı yeter sayısı sağlanmışsa durum bir tutanakla tespit
            edilir ve toplantı yönetim kurulu başkanı veya görevlendireceği
            yönetim kurulu üyelerinden biri tarafından açılır. Toplantı yeter
            sayısı sağlanamaması halinde de yönetim kurulunca bir tutanak
            düzenlenir.
          </p>

          <p>
            Açılıştan sonra, toplantıyı yönetmek üzere bir başkan ve yeteri
            kadar başkan vekili ile yazman seçilerek divan heyeti oluşturulur.
          </p>

          <p>
            Vakıf organlarının seçimi için yapılacak oylamalarda, oy kullanan
            üyelerin divan heyetine kimliklerini göstermeleri ve hazırun
            listesindeki isimlerinin karşılarını imzalamaları zorunludur.
          </p>
          <p>
            Toplantının yönetimi ve güvenliğinin sağlanması divan başkanına
            aittir.
          </p>
          <p>
            Genel kurulda, yalnızca gündemde yer alan maddeler görüşülür. Ancak
            toplantıda hazır bulunan üyelerin onda biri tarafından görüşülmesi
            yazılı olarak istenen konuların gündeme alınması zorunludur.
          </p>
          <p>
            Genel kurulda her üyenin bir oy hakkı vardır; üye oyunu şahsen
            kullanmak zorundadır. Onursal üyeler genel kurul toplantılarına
            katılabilir ancak oy kullanamazlar. Tüzel kişinin üye olması
            halinde, tüzel kişinin yönetim kurulu başkanı veya temsille
            görevlendireceği kişi oy kullanır.
          </p>
          <p>
            Toplantıda görüşülen konular ve alınan kararlar bir tutanağa yazılır
            ve divan başkanı ile yazmanlar tarafından birlikte imzalanır.
            Toplantı sonunda, tutanak ve diğer belgeler yönetim kurulu başkanına
            teslim edilir. Yönetim kurulu başkanı bu belgelerin korunmasından ve
            yeni seçilen yönetim kuruluna yedi gün içinde teslim etmekten
            sorumludur.
          </p>
          <p>
            <b>Genel Kurulun Oy kullanma ve Karar Alma Usul ve Şekilleri</b>
          </p>
          <p>
            <b>Madde 8-</b>Genel kurulda, aksine karar alınmamışsa, oylamalar
            açık olarak yapılır. Açık oylamada, genel kurul başkanının
            belirteceği yöntem uygulanır.
          </p>
          <p>
            Gizli oylama yapılacak olması durumunda ise, toplantı başkanı
            tarafından mühürlenmiş kağıtlar veya oy pusulaları üyeler tarafından
            gereği yapıldıktan sonra içi boş bir kaba atılır ve oy vermenin
            bitiminden sonra açık dökümü yapılarak sonuç belirlenir.
          </p>
          <p>
            Genel kurul kararları, toplantıya katılan üyelerin salt çoğunluğuyla
            alınır. Şu kadar ki, tüzük değişikliği ve vakfın feshi kararları,
            ancak toplantıya katılan üyelerin üçte iki çoğunluğuyla alınabilir.
          </p>
          <p>
            <b>Toplantısız veya Çağrısız Alınan Kararlar</b>
          </p>
          <p>
            Bütün üyelerin bir araya gelmeksizin yazılı katılımıyla alınan
            kararlar ile vakıf üyelerinin tamamının bu tüzükte yazılı çağrı
            usulüne uymaksızın bir araya gelerek aldığı kararlar geçerlidir. Bu
            şekilde karar alınması olağan toplantı yerine geçmez.
          </p>
          <p>
            <b>Genel Kurulun Görev ve Yetkileri</b>
          </p>
          <p>
            <b>Madde 9-</b>Aşağıda yazılı hususlar genel kurulca görüşülüp
            karara bağlanır.
          </p>
          <p>1-Vakıf organlarının seçilmesi,</p>
          <p>2-Vakıf tüzüğünün değiştirilmesi,</p>
          <p>
            3-Yönetim ve denetim kurulları raporlarının görüşülmesi ve yönetim
            kurulunun ibrası,
          </p>
          <p>
            4-Yönetim kurulunca hazırlanan bütçenin görüşülüp aynen veya
            değiştirilerek kabul edilmesi,
          </p>
          <p>
            5-Vakfın diğer organlarının denetlenmesi ve gerek görüldüğünde haklı
            sebeplerle onların görevden alınması,
          </p>
          <p>
            6- Üyeliğin reddi veya üyelikten çıkarma hakkında verilen yönetim
            kurulu kararlarına karşı yapılan itirazların incelenmesi ve karara
            bağlanması,
          </p>
          <p>
            7-Vakıf için gerekli olan taşınmaz malların satın alınması veya
            mevcut taşınmaz malların satılması hususunda yönetim kuruluna yetki
            verilmesi,
          </p>
          <p>
            8-Yönetim kurulunca vakıf çalışmaları ile ilgili olarak hazırlanacak
            yönetmelikleri inceleyip aynen veya değiştirilerek onaylanması,
          </p>
          <p>
            9-Vakıf yönetim ve denetim kurullarının kamu görevlisi olmayan
            başkan ve üyelerine verilecek ücret ile her türlü ödenek, yolluk ve
            tazminatlar ile vakıf hizmetleri için görevlendirilecek üyelere
            verilecek gündelik ve yolluk miktarlarının tespit edilmesi,
          </p>
          <p>
            10-Vakfın federasyona katılması ve ayrılmasının kararlaştırılması,
          </p>
          <p>
            11-Vakfın şubelerinin açılmasının kararlaştırılması ve açılmasına
            karar verilen şube ile ilgili işlemlerin yürütülmesi husunda yönetim
            kuruluna yetki verilmesi,
          </p>
          <p>
            12-Vakfın uluslar arası faaliyette bulunması, yurt dışındaki vakıf
            ve kuruluşlara üye olarak katılması veya ayrılması,
          </p>
          <p>13-Derneğin vakıf kurması,</p>
          <p>14-Vakfın fesih edilmesi,</p>
          <p>
            15-Yönetim kurulunun diğer önerilerinin incelenip karara bağlanması,
          </p>
          <p>
            16-Vakfın en yetkili organı olarak vakfın diğer bir organına
            verilmemiş olan işlerin görülmesi ve yetkilerin kullanılması,
          </p>
          <p>
            17-Mevzuatta genel kurulca yapılması belirtilen diğer görevlerin
            yerine getirilmesi,
          </p>
          <p>
            <b>Yönetim Kurulunun Teşkili, Görev ve Yetkileri</b>
          </p>
          <p>
            <b>Madde 10-</b>Yönetim kurulu beş asıl ve beş yedek üye olarak
            genel kurulca seçilir. Yönetim kurulu, seçimden sonraki ilk
            toplantısında bir kararla görev bölüşümü yaparak başkan, başkan
            yardımcısı, sekreter, sayman ve üye’yi belirler.
          </p>
          <p>
            Yönetim kurulu, tüm üyelerin haber edilmesi şartıyla her zaman
            toplantıya çağrılabilir. Üye tamsayısının yarısından bir fazlasının
            hazır bulunması ile toplanır. Kararlar, toplantıya katılan üye tam
            sayısının salt çoğunluğu ile alınır.
          </p>
          <p>
            Yönetim kurulu asıl üyeliğinde istifa veya başka sebeplerden dolayı
            boşalma olduğu taktirde genel kurulda aldığı oy çokluğu sırasına
            göre yedek üyelerin göreve çağrılması mecburidir.
          </p>
          <p>
            <b>Yönetim Kurulunun Görev ve Yetkileri</b>
          </p>
          <p>Yönetim kurulu aşağıdaki hususları yerine getirir.</p>
          <p>
            1-Vakfı temsil etmek veya bu hususta kendi üyelerinden birine veya
            bir üçüncü kişiye yetki vermek,
          </p>
          <p>
            2-Gelir ve gider hesaplarına ilişkin işlemleri yapmak ve gelecek
            döneme ait bütçeyi hazırlayarak genel kurula sunmak,
          </p>
          <p>
            3-Vakfın çalışmaları ile ilgili yönetmelikleri hazırlayarak genel
            kurul onayına sunmak,
          </p>
          <p>
            4-Genel kurulun verdiği yetki ile taşınmaz mal satın almak, vakfa
            ait taşınır ve taşınmaz malları satmak, bina veya tesis inşa
            ettirmek, kira sözleşmesi yapmak, vakıf lehine rehin ipotek veya
            ayni haklar tesis ettirmek,
          </p>
          <p>
            5-Genel kurulun verdiği yetki ile şube açmaya ilişkin işlemlerin
            yürütülmesini sağlamak,
          </p>
          <p>6-Vakfın şubelerinin denetlenmesini sağlamak,</p>
          <p>7-Gereli görülen yerlerde temsilcilik açılmasını sağlamak,</p>
          <p>8-Genel kurulda alınan kararları uygulamak,</p>
          <p>
            {" "}
            9-Her faaliyet yılı sonunda vakfın işletme hesabı tablosu veya
            bilanço ve gelir tablosu ile yönetim kurulu çalışmalarını açıklayan
            raporunu düzenlemek, toplandığında genel kurula sunmak,
          </p>
          <p> 10-Bütçenin uygulanmasını sağlamak,</p>
          <p>
            {" "}
            11-Vakıf üye alınması veya üyelikten çıkarılma hususlarında karar
            vermek,
          </p>
          <p>
            {" "}
            12-Vakfın amacını gerçekleştirmek için yetkisi dahilinde her çeşit
            kararı almak ve uygulamak,
          </p>
          <p>
            {" "}
            13-Mevzuatın kendisine verdiği diğer görevleri yapmak ve yetkileri
            kullanmak,
          </p>
          <p>
            <b>Denetim Kurulunun Teşkili, Görev ve Yetkileri</b>
          </p>
          <p>
            <b></b>Madde 11-
            <b />
            Denetim kurulu, üç asıl ve üç yedek üye olarak genel kurulca
            seçilir. Denetim kurulu asıl üyeliğinde istifa veya başka
            sebeplerden dolayı boşalma olduğu taktirde genel kurulda aldığı oy
            çokluğu sırasına göre yedek üyelerin göreve çağrılması mecburidir.
          </p>
          <p>
            <b>Denetim Kurulunun Görev ve Yetkileri</b>
          </p>
          <p>
            Denetim kurulu; vakfın, tüzüğünde gösterilen amaç ve amacın
            gerçekleştirilmesi için sürdürüleceği belirtilen çalışma konuları
            doğrultusunda faaliyet gösterip göstermediğini, defter, hesap ve
            kayıtların mevzuata ve vakıf tüzüğüne uygun olarak tutulup
            tutulmadığını, vakıf tüzüğünde tespit edilen esas ve usullere göre
            ve bir yılı geçmeyen aralıklarla denetler ve denetim sonuçlarını bir
            rapor halinde yönetim kuruluna ve toplandığında genel kurula sunar.
          </p>
          <p>Denetim kurulu, gerektiğinde genel kurulu toplantıya çağırır.</p>
          <p>
            <b>Vakfın Gelir Kaynakları</b>
          </p>
          <p>
            <b>Madde 12-</b>Vakfın gelir kaynakları aşağıda sayılmıştır.
          </p>
          <p>
            1-Üye aidatı: Üyelerden giriş ödentisi olarak 500 TL, aylık olarak
            ta 500 TL aidat alınır. Bu miktarları artırmaya veya eksiltmeye
            yönetim kurulu yetkilidir,
          </p>
          <p>
            {" "}
            2-Şube ödentisi: Vakfın genel giderlerini karşılamak üzere şubeler
            tarafından tahsil edilen üye ödentilerinin %50’si altı ayda bir
            genel merkeze gönderilir,
          </p>
          <p>
            {" "}
            3-Gerçek ve tüzel kişilerin kendi isteği ile vakfa yaptıkları bağış
            ve yardımlar,
          </p>
          <p>
            {" "}
            4-Vakıf tarafından tertiplenen çay ve yemekli toplantı, gezi ve
            eğlence, temsil, konser, spor yarışması ve konferans gibi
            faaliyetlerden sağlanan gelirler,
          </p>
          <p> 5-Vakfın mal varlığından elde edilen gelirler,</p>
          <p>
            {" "}
            6-Yardım toplama hakkındaki mevzuat hükümlerine uygun olarak
            toplanacak bağış ve yardımlar,
          </p>
          <p>
            {" "}
            7-Vakfın, amacını gerçekleştirmek için ihtiyaç duyduğu geliri temin
            etmek amacıyla giriştiği ticari faaliyetlerden elde edilen
            kazançlar,
          </p>
          <p> 8-Diğer gelirler.</p>
          <p>
            <b>Vakfın Defter Tutma Esas ve Usulleri ve Tutulacak Defterler</b>
          </p>
          <p>
            <b>Madde 13-Defter tutma esasları;</b>
          </p>
          <p>
            Vakıfta, işletme hesabı esasına göre defter tutulur. Ancak, yıllık
            brüt gelirin Vakıflar Yönetmeliğinin 31. Maddesinde belirtilen haddi
            aşması durumunda takip eden hesap döneminden başlayarak bilanço
            esasına göre defter tutulur.
          </p>
          <p>
            Bilanço esasına geçilmesi durumunda, üst üste iki hesap döneminde
            yukarıda belirtilen haddin altına düşülürse, takip eden yıldan
            itibaren işletme hesabı esasına dönülebilir.
          </p>
          <p>
            Yukarıda belirtilen hadde bağlı kalmaksızın yönetim kurulu kararı
            ile bilanço esasına göre defter tutulabilir.
          </p>
          <p>
            Vakfın ticari işletmesi açılması durumunda, bu ticari işletme için,
            ayrıca Vergi Usul Kanunu hükümlerine göre defter tutulur.
          </p>
          <p>
            <b>Kayıt Usulü</b>
          </p>
          <p>
            Vakfın defter ve kayıtları Vakıf Yönetmeliğinde belirtilen usul ve
            esasa uygun olarak tutulur.
          </p>
          <p>
            <b>Tutulacak Defterler</b>
          </p>
          <p>Vakıfta, aşağıda yazılı defterler tutulur.</p>
          <p>
            <b>
              a)İşletme hesabı esasında tutulacak defterler ve uyulacak esaslar
              aşağıdaki gibidir:
            </b>
          </p>
          <p>
            1-Karar Defteri: Yönetim kurulu kararları tarih ve numara sırasıyla
            bu deftere yazılır ve kararların altı toplantıya katılan üyelerce
            imzalanır.
          </p>
          <p>
            2-Üye Kayıt Defteri: Vakfa üye olarak girenlerin kimlik bilgileri,
            vakfa giriş ve çıkış tarihleri bu deftere işlenir. Üyelerin
            ödedikleri giriş ve yıllık aidat miktarları bu deftere işlenebilir.
          </p>
          <p>
            3-Evrak Kayıt Defteri: Gelen ve giden evraklar, tarih ve sıra
            numarası ile bu deftere kaydedilir. Gelen evrakın asılları ve giden
            evrakın kopyaları dosyalanır. Elektronik posta yoluyla gelen veya
            giden evraklar çıktısı alınmak suretiyle saklanır.
          </p>
          <p>
            4-İşletme Hesabı Defteri: Vakıf adına alınan gelirler ve yapılan
            giderler açık ve düzenli olarak bu deftere işlenir.
          </p>
          <p>
            5-Alındı Belgesi Kayıt Defteri: Alındı belgelerinin seri ve sıra
            numaraları, bu belgeleri alan ve iade edelerin adı, soyadı ve
            imzaları ile aldıkları ve iade ettikleri tarihler bu deftere
            işlenir.
          </p>
          <p>
            6-Demirbaş Defteri: Vakfa ait demirbaşların edinme tarihi ve şekli
            ile kullanıldıkları veya verildikleri yerler ve kullanım sürelerini
            dolduranların kayıttan düşürülmesi bu deftere işlenir.
          </p>
          <p>
            <i>
              Alındı Belgesi Kayıt Defteri ile Demirbaş Defterinin tutulması
              zorunlu değildir.
            </i>
          </p>
          <p>
            <b>
              b)Bilanço esasında tutulacak defterler ve uyulacak esaslar
              aşağıdaki gibidir:
            </b>
          </p>
          <p>
            1-(a) bendinin 1, 2 ve 3 üncü alt bentlerinde kayıtlı defterler
            bilanço esasında defter tutulması durumunda da tutulur.
          </p>
          <p>
            2-Yevmiye Defteri ve Büyük Defter: Bu defterlerin tutulma usulü ile
            kayıt şekli Vergi Usul Kanunu ile bu Kanununun Maliye Bakanlığına
            verdiği yetkiye istinaden yayımlanan Muhasebe Sistemi Uygulama Genel
            Tebliğleri esaslarına göre yapılır.
          </p>
          <p>
            <b>Defterlerin Tasdiki</b>
          </p>
          <p>
            Vakıfta, tutulması zorunlu olan defterler (Büyük Defter hariç),
            kullanmaya başlamadan önce il dernekler müdürlüğüne veya notere
            tasdik ettirilir. Bu defterlerin kullanılmasına sayfaları bitene
            kadar devam edilir ve defterlerin ara tasdiki yapılmaz.
          </p>
          <p>
            Ancak, bilanço esasına göre tutulan Yevmiye Defteri’nin
            kullanılacağı yıldan önce gelen son ayda, her yıl yeniden tasdik
            ettirilmesi zorunludur.
          </p>
          <p>
            <b>Gelir Tablosu ve Bilanço Düzenlenmesi</b>
          </p>
          <p>
            İşletme hesabı esasına göre kayıt tutulması durumunda yıl sonlarında
            (31 Aralık) (Vakıflar Yönetmeliği EK-16’da belirtilen) “İşletme
            Hesabı Tablosu” düzenlenir. Bilanço esasına göre defter tutulması
            durumunda ise, yılsonlarında (31 Aralık), Maliye Bakanlığınca
            yayımlanan Muhasebe Sistemi Uygulama Genel Tebliğlerini esas alarak
            bilanço ve gelir tablosu düzenlenir.
          </p>
          <p>
            <b>Vakfın Gelir ve Gider İşlemleri</b>
          </p>
          <p>
            <b>Madde 14-</b>Gelir ve gider belgeleri;
          </p>
          <p>
            Vakıf gelirleri, (Vakıflar Yönetmeliği EK- 17’de örneği bulunan)
            “Alındı Belgesi” ile tahsil edilir. Vakıf gelirlerinin bankalar
            aracılığı ile tahsili halinde banka tarafından düzenlenen dekont
            veya hesap özeti gibi belgeler alındı belgesi yerine geçer.
          </p>
          <p>
            Vakıf giderleri ise fatura, perakende satış fişi, serbest meslek
            makbuzu gibi harcama belgeleri ile yapılır. Ancak vakfın, Gelir
            Vergisi Kanununun 94’üncü maddesi kapsamında bulunan ödemeleri için
            Vergi Usul Kanunu hükümlerine göre gider pusulası, bu kapsamda da
            bulunmayan ödemeleri için (Vakıflar Yönetmeliği EK-13’te örneği
            buluna) “Gider Makbuzu” veya “Banka Dekontu” gibi belgeler harcama
            belgesi olarak kullanılır.
          </p>
          <p>
            Vakıf tarafından kişi, kurum veya kuruluşlara yapılacak bedelsiz mal
            ve hizmet teslimleri (Vakıflar Yönetmeliği EK-14’te örneği bulunan)
            “Ayni Yardım Teslim Belgesi” ile yapılır. Kişi, kurum veya
            kuruluşlar tarafından vakfa yapılacak bedelsiz mal ve hizmet
            teslimleri ise (Vakıflar Yönetmeliği EK-15’te örneği bulunan) “Ayni
            Bağış Alındı Belgesi” ile kabul edilir.
          </p>
          <p>
            Bu belgeler; Ek-13, Ek-14 ve Ek-15’te gösterilen biçim ve ebatta,
            müteselsil seri ve sıra numarası taşıyan, kendinden karbonlu elli
            asıl ve elli koçan yaprağından meydana gelen ciltler veya elektronik
            sistemler ve yazı makineleri aracılığıyla yazdırılacak form veya
            sürekli form şeklinde bastırılır. Form veya sürekli form şeklinde
            bastırılacak belgelerin, belirtilen nitelikte olması zorunludur.
          </p>
          <p>
            <b>Alındı Belgeleri</b>
          </p>
          <p>
            Vakıf gelirlerinin tahsilinde kullanılacak “Alındı Belgeleri”
            (Vakıflar Yönetmeliği EK- 17’de gösterilen biçim ve ebatta) yönetim
            kurulu kararıyla, matbaaya bastırılır.
          </p>
          <p>
            Alındı belgelerinin bastırılması ve kontrolü, matbaadan teslim
            alınması, deftere kaydedilmesi, eski ve yeni saymanlar arasında
            devir teslimi ve alındı belgesi ile vakıf adına gelir tahsil edecek
            kişi veya kişiler tarafından bu alındı belgelerinin kullanımına ve
            toplanılan gelirlerin teslimine ilişkin hususlarda Vakıflar
            Yönetmeliğinin ilgili hükümlerine göre hareket edilir.
          </p>
          <p>
            <b>Yetki Belgesi</b>
          </p>
          <p>
            Yönetim kurulu asıl üyeleri hariç, vakıf adına gelir tahsil edecek
            kişi veya kişiler, yetki süresi de belirtilmek suretiyle, yönetim
            kurulu kararı ile tespit edilir. Gelir tahsil edecek kişilerin açık
            kimliği, imzası ve fotoğraflarını ihtiva eden (Vakıflar Yönetmeliği
            Ek-19’da yer alan) “Yetki Belgesi” vakıf tarafından iki nüsha olarak
            düzenlenerek, vakıf yönetim kurulu başkanınca onaylanır. Yönetim
            kurulu asıl üyeleri yetki belgesi olmadan gelir tahsil edebilir.
          </p>
          <p>
            Yetki belgelerinin süresi yönetim kurulu tarafından en çok bir yıl
            olarak belirlenir. Süresi biten yetki belgeleri birinci fıkraya göre
            yenilenir. Yetki belgesinin süresinin bitmesi veya adına yetki
            belgesi düzenlenen kişinin görevinden ayrılması, ölümü, işine veya
            görevine son verilmesi gibi hallerde, verilmiş olan yetki
            belgelerinin vakıf yönetim kuruluna bir hafta içinde teslimi
            zorunludur. Ayrıca, gelir toplama yetkisi yönetim kurulu kararı ile
            her zaman iptal edilebilir.”
          </p>
          <p>
            <b>Gelir ve Gider Belgelerinin Saklama Süresi;</b>
          </p>
          <p>
            Defterler hariç olmak üzere, vakıf tarafından kullanılan alındı
            belgeleri, harcama belgeleri ve diğer belgeler özel kanunlarda
            belirtilen süreler saklı kalmak üzere, kaydedildikleri defterlerdeki
            sayı ve tarih düzenine uygun olarak 5 yıl süreyle saklanır.
          </p>
          <p>
            <b>Beyanname Verilmesi</b>
          </p>
          <p>
            <b>Madde 15-</b>, Vakfın, bir önceki yıla ait faaliyetleri ile gelir
            ve gider işlemlerinin yıl sonu itibarıyla sonuçlarına ilişkin
            (Vakıflar Yönetmeliği EK-21’de sunulan) “Vakıf Beyannamesi” vakıf
            yönetim kurulu tarafından onaylandıktan sonra, her takvim yılının
            ilk dört ayı içinde vakıf başkanı tarafından ilgili mülki idare
            amirliğine verilir.
          </p>
          <p>
            <b>Bildirim Yükümlülüğü</b>
          </p>
          <p>Madde 16-Mülki amirliğe yapılacak bildirimler;</p>
          <p>
            <b>Genel Kurul Sonuç Bildirimi</b>
          </p>
          <p>
            Olağan veya olağanüstü genel kurul toplantılarını izleyen otuz gün
            içinde, yönetim ve denetim kurulları ile diğer organlara seçilen
            asıl ve yedek üyeleri içeren (Vakıflar Yönetmeliği Ek-3’te yer alan)
            Genel Kurul Sonuç Bildirimi mülki idare amirliğine verilir. Genel
            kurul toplantısında tüzük değişikliği yapılması halinde; genel kurul
            toplantı tutanağı, tüzüğün değişen maddelerinin eski ve yeni şekli,
            her sayfası yönetim kurulu üyelerinin salt çoğunluğunca imzalanmış
            vakıf tüzüğünün son şekli, bu fıkrada belirtilen süre içinde ve bir
            yazı ekinde mülki idare amirliğine verilir
          </p>
          <p>
            <b>
              Olağan veya olağanüstü genel kurul toplantılarını izleyen otuz gün
              içinde, yönetim ve denetim kurulları ile diğer organlara seçilen
              asıl ve yedek üyeleri içeren (Vakıflar Yönetmeliği Ek-3’te yer
              alan) Genel Kurul Sonuç Bildirimi mülki idare amirliğine verilir.
              Genel kurul toplantısında tüzük değişikliği yapılması halinde;
              genel kurul toplantı tutanağı, tüzüğün değişen maddelerinin eski
              ve yeni şekli, her sayfası yönetim kurulu üyelerinin salt
              çoğunluğunca imzalanmış vakıf tüzüğünün son şekli, bu fıkrada
              belirtilen süre içinde ve bir yazı ekinde mülki idare amirliğine
              verilir
            </b>
          </p>
          <p>
            Vakfın edindiği taşınmazlar tapuya tescilinden itibaren otuz gün
            içinde (Vakıflar Yönetmeliği EK-26’da sunulan) “Taşınmaz Mal
            Bildirimi”ni doldurmak suretiyle mülki idare amirliğine bildirilir.
          </p>
          <p>
            <b>Yurtdışından Yardım Alma Bildirimi</b>
          </p>
          <p>
            Vakıf tarafından, yurtdışından yardım alınacak olması durumunda
            yardım alınmadan önce (Vakıflar Yönetmeliği EK-4’te belirtilen)
            “Yurtdışından Yardım Alma Bildirimi” doldurup mülki idare amirliğine
            bildirimde bulunulur.
          </p>
          <p>
            Nakdi yardımların bankalar aracılığıyla alınması ve kullanılmadan
            önce bildirim şartının yerine getirilmesi zorunludur.
          </p>
          <p>
            <b>Değişikliklerin Bildirilmesi</b>
          </p>
          <p>
            Vakfın yerleşim yerinde meydana gelen değişiklik (Vakıflar
            Yönetmeliği EK-24’te belirtilen) “Yerleşim Yeri Değişiklik
            Bildirimi”; genel kurul toplantısı dışında vakıf organlarında
            meydana gelen değişiklikler (Vakıflar Yönetmeliği EK-25’te
            belirtilen) “Vakıf Organlarındaki Değişiklik Bildirimi” doldurulmak
            suretiyle, değişikliği izleyen otuz gün içinde mülki idare
            amirliğine bildirilir.
          </p>
          <p>
            Vakıf tüzüğünde yapılan değişiklikler de tüzük değişikliğinin
            yapıldığı genel kurul toplantısını izleyen otuz gün içinde, genel
            kurul sonuç bildirimi ekinde mülki idare amirliğine bildirilir.
          </p>
          <p>
            <b>Vakfın İç Denetimi</b>
          </p>
          <p>
            <b>Madde 17-</b>Vakıfta genel kurul, yönetim kurulu veya denetim
            kurulu tarafından iç denetim yapılabileceği gibi, bağımsız denetim
            kuruluşlarına da denetim yaptırılabilir. Genel kurul, yönetim kurulu
            veya bağımsız denetim kuruluşlarınca denetim yapılmış olması,
            denetim kurulunun yükümlülüğünü ortadan kaldırmaz.
          </p>
          <p>
            Denetim kurulu tarafından en geç yılda bir defa vakfın denetimi
            gerçekleştirilir. Genel kurul veya yönetim kurulu, gerek görülen
            hallerde denetim yapabilir veya bağımsız denetim kuruluşlarına
            denetim yaptırabilir.
          </p>
          <p>
            <b>Vakfın Borçlanma Usulleri</b>
          </p>
          <p>
            <b>Madde 18-</b>Vakıf amacını gerçekleştirmek ve faaliyetlerini
            yürütebilmek için ihtiyaç duyulması halinde yönetim kurulu kararı
            ile borçlanma yapabilir. Bu borçlanma kredili mal ve hizmet alımı
            konularında olabileceği gibi nakit olarak ta yapılabilir. Ancak bu
            borçlanma, vakfın gelir kaynakları ile karşılanamayacak miktarlarda
            ve vakfın ödeme güçlüğüne düşürecek nitelikte yapılamaz.
          </p>
          <p>
            <b>Vakfın Şubelerinin Kuruluşu</b>
          </p>
          <p>
            <b>Madde 19-</b>Vakıf, gerekli görülen yerlerde genel kurul
            kararıyla şube açabilir. Bu amaçla vakıf yönetim kurulunca yetki
            verilen en az üç kişilik kurucular kurulu, Vakıflar Yönetmeliği’nde
            belirtilen şube kuruluş bildirimini ve gerekli belgeleri, şube
            açılacak yerin en büyük mülki amirliğine verir.
          </p>
          <p>
            <b>Şubelerin Görev ve Yetkileri </b>
          </p>
          <p>
            Madde 20-Şubeler, tüzel kişiliği olamayan, vakıf amaç ve hizmet
            konuları doğrultusunda özerk faaliyetlerde bulunmakla görev ve
            yetkili, tüm işlemlerinden doğan alacak ve borçlarından ötürü
            kendisinin sorumlu olduğu vakıf iç örgütüdür.
          </p>
          <p>
            <b>Şubelerin Organları ve Şubelere Uygulanacak Hükümler</b>
          </p>
          <p>
            <b>Madde 21-</b> Şubenin organları, genel kurul, yönetim kurulu ve
            denetim kurulu’dur.
          </p>
          <p>
            Genel kurul, şubenin kayıtlı üyelerinden oluşur. Yönetim kurulu, beş
            asıl ve beş yedek, denetim kurulu ise üç asıl ve üç yedek üye olarak
            şube genel kurulunca seçilir.
          </p>
          <p>
            Bu organların görev ve yetkileri ile bu tüzükte yer alan vakıfla
            ilgili diğer hükümler, mevzuatın öngördüğü çerçevede şube’de de
            uygulanır.
          </p>
          <p>
            Şubelerin Genel Kurullarının Toplanma Zamanı ve Genel Merkez Genel
            Kurulunda Nasıl Temsil Edileceği
          </p>
          <p>
            <b>Madde 22-</b>Şubeler, genel kurul olağan toplantılarını genel
            merkez genel kurulu toplantısından en az iki ay önce bitirmek
            zorundadırlar.
          </p>
          <p>
            Şubelerin olağan genel kurulu, 3 yılda bir, Mart ayı içerisinde,
            şube yönetim kurulunca belirlenecek gün yer ve saatte toplanır.
          </p>
          <p>
            Şubeler, genel kurul sonuç bildiriminin bir örneğini toplantının
            yapıldığı tarihi izleyen otuz gün içinde mülki idare amirliğine ve
            vakıf genel merkezine bildirmek zorundadırlar.
          </p>
          <p>
            Şubeler, şube sayısı üçe kadar genel merkez genel kurulunda tüm
            üyelerin doğrudan katılımı ile; şube sayısı üçten fazla olması
            durumunda ise, şubede kayıtlı her yirmi (20) üye için bir (1), arta
            kalan üye sayısı 10‘dan fazla ise bu üyeler içinde bir olmak üzere
            şube genel kurulunda seçilecek delegeler aracılığı ile genel merkez
            genel kuruluna katılma hakkına sahiptir.
          </p>
          <p>
            Genel merkez genel kuruluna en son şube genel kurulunda seçilen
            delegeler katılır. Genel merkez yönetim ve denetim kurulu üyeleri
            genel merkez genel kuruluna katılır, ancak şube adına delege
            seçilmedikleri sürece oy kullanamazlar.
          </p>
          <p>
            Şubelerin yönetim veya denetim kurulunda görevli olanlar genel
            merkez yönetim veya denetim kuruluna seçildiklerinde şubedeki
            görevinden ayrılırlar.
          </p>
          <p>
            <b>Temsilcilik Açma</b>
          </p>
          <p>
            Madde 23-Vakıf, gerekli gördüğü yerlerde vakıf faaliyetlerini
            yürütmek amacıyla yönetim kurulu kararıyla temsilcilik açabilir.
            Temsilciliğin adresi, yönetim kurulu kararıyla temsilci olarak
            görevlendirilen kişi veya kişiler tarafından o yerin mülkî idare
            amirliğine yazılı olarak bildirilir. Temsilcilik, vakıf genel
            kurulunda temsil edilmez. Şubeler temsilcilik açamazlar.
          </p>
          <p>
            <b>Tüzüğün Ne Şekilde Değiştirileceği?</b>
          </p>
          <p>
            <b>Madde 24-</b>Tüzük değişikliği genel kurul kararı ile
            yapılabilir.
          </p>
          <p>
            Genel kurulda tüzük değişikliği yapılabilmesi için genel kurula
            katılma ve oy kullanma hakkı bulunan üyelerin 2/3 çoğunluğu aranır.
            Çoğunluğun sağlanamaması sebebiyle toplantının ertelenmesi durumunda
            ikinci toplantıda çoğunluk aranmaz. Ancak, bu toplantıya katılan üye
            sayısı, yönetim ve denetim kurulları üye tam sayısının iki katından
            az olamaz.
          </p>
          <p>
            Tüzük değişikliği için gerekli olan karar çoğunluğu toplantıya
            katılan ve oy kullanma hakkı bulunan üyelerin oylarının 2/3’ü’dür.
            Genel kurulda tüzük değişikliği oylaması açık olarak yapılır.
          </p>
          <p>
            <b>Vakfın Feshi ve Mal Varlığının Tasfiye Şekli</b>
          </p>
          <p>
            <b>Madde 25-</b>Genel kurul, her zaman vakfın feshine karar
            verebilir.
          </p>
          <p>
            Genel kurulda fesih konusunun görüşülebilmesi için genel kurula
            katılma ve oy kullanma hakkı bulunan üyelerin 2/3 çoğunluğu aranır.
            Çoğunluğun sağlanamaması sebebiyle toplantının ertelenmesi durumunda
            ikinci toplantıda çoğunluk aranmaz. Ancak, bu toplantıya katılan üye
            sayısı, yönetim ve denetim kurulları üye tam sayısının iki katından
            az olamaz.
          </p>
          <p>
            Fesih kararının alınabilmesi için gerekli olan karar çoğunluğu
            toplantıya katılan ve oy kullanma hakkı bulunan üyelerin oylarının
            2/3’ü’dür. Genel kurulda fesih kararı oylaması açık olarak yapılır.
          </p>
          <p>
            <b>Tasfiye İşlemleri</b>
          </p>
          <p>
            Genel kurulca fesih kararı verildiğinde, vakfın para, mal ve
            haklarının tasfiyesi son yönetim kurulu üyelerinden oluşan tasfiye
            kurulunca yapılır. Bu işlemlere, feshe ilişkin genel kurul kararının
            alındığı veya kendiliğinden sona erme halinin kesinleştiği tarihten
            itibaren başlanır. Tasfiye süresi içinde bütün işlemlerde vakıf
            adında “Tasfiye Halinde Nizam-ı Turan Ocakları Eğitim ve Kültür
            Vakfı” ibaresi kullanılır.
          </p>
          <p>
            Tasfiye kurulu, mevzuata uygun olarak vakfın para, mal ve haklarının
            tasfiyesi işlemlerini baştan sonuna kadar tamamlamakla görevli ve
            yetkilidir. Bu kurul, önce vakfın hesaplarını inceler. İnceleme
            esnasında vakfa ait defterler, alındı belgeleri, harcama belgeleri,
            tapu ve banka kayıtları ile diğer belgelerinin tespiti yapılarak
            varlık ve yükümlülükleri bir tutanağa bağlanır. Tasfiye işlemeleri
            sırasında vakfın alacaklılarına çağrıda bulunulur ve varsa malları
            paraya çevrilerek alacaklılara ödenir. Vakfın alacaklı olması
            durumunda alacaklar tahsil edilir. Alacakların tahsil edilmesi ve
            borçların ödenmesinden sonra kalan tüm para, mal ve hakları, genel
            kurulda belirlenen yere devredilir. Genel kurulda, devredilecek yer
            belirlenmemişse vakfın bulunduğu ildeki amacına en yakın ve fesih
            edildiği tarihte en fazla üyeye sahip vakfa devredilir.
          </p>
          <p>
            Tasfiyeye ilişkin tüm işlemler tasfiye tutanağında gösterilir ve
            tasfiye işlemleri, mülki idare amirliklerince haklı bir nedene
            dayanılarak verilen ek süreler hariç üç ay içinde tamamlanır.
          </p>
          <p>
            Vakfın para, mal ve haklarının tasfiye ve intikal işlemlerinin
            tamamlanmasını müteakip tasfiye kurulu tarafından durumun yedi gün
            içinde bir yazı ile vakıf merkezinin bulunduğu yerin mülki idare
            amirliğine bildirilmesi ve bu yazıya tasfiye tutanağının da
            eklenmesi zorunludur.
          </p>
          <p>
            Vakfın defter ve belgelerini tasfiye kurulu sıfatıyla son yönetim
            kurulu üyeleri saklamakla görevlidir. Bu görev, bir yönetim kurulu
            üyesine de verilebilir. Bu defter ve belgelerin saklanma süresi beş
            yıldır.
          </p>
          <p>
            <b>Hüküm Eksikliği</b>
          </p>
          <p>
            <b>Madde 26-</b>Bu tüzükte belirtilmemiş hususlarda Vakıflar Kanunu,
            Türk Medeni Kanunu ve bu kanunlara atfen çıkartılmış olan Vakıflar
            Yönetmeliği ve ilgili diğer mevzuatın Vakıflar hakkındaki hükümleri
            uygulanır.
          </p>
          <p>Bu tüzük 26 (Yirmialtı) maddeden ibarettir.</p>
        </div>
      </div>
    </PageLayouts>
  );
};

export default Tuzuk;
