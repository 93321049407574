import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from '../../layouts/MetaData'

const Hakkimizda = () => {
  return (
    <PageLayouts>
      <MetaData title={"Hakkımızda"} />
      <div id="content-dinamik-title">
        <div id="content-title-type">HAKKIMIZDA</div>
      </div>
      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="baskanlik-content">
          <p>
            <b>HAKKIMIZDA</b>
          </p>
          <p>
            Turan Ocakları Eğitim ve Kültür Vakfı, Türk milletinin tarihine,
            kültürüne ve milli değerlerine duyduğu derin sevgi ve bağlılıkla
            kurulmuş bir sivil toplum kuruluşudur. Vakfımız, eğitim ve kültür
            alanlarında etkin bir rol oynayarak genç nesillerin milli
            kimlikleriyle bütünleşmelerine, liderlik vasıflarını
            geliştirmelerine ve vatanlarına daha fazla hizmet etmelerine
            odaklanır.
          </p>
          <p>
            <b>Vizyonumuz:</b>
          </p>
          <p>
            Turan Ocakları Eğitim ve Kültür Vakfı, Türk milletinin zengin
            tarihini ve kültür mirasını gelecek kuşaklara taşımak, gençleri
            milli değerlerle buluşturarak Türk kültürünü geliştirmek, eğitimde
            öncü projelerle toplumun bilinç düzeyini yükseltmek ve birlik
            beraberlik ruhunu güçlendirmek için çaba harcar.
          </p>
          <p>
            <b>Misyonumuz:</b>
          </p>
          <p>
            - Türk gençliğini vatansever, bilinçli ve liderlik vasıflarına sahip
            bireyler olarak yetiştirmek.
          </p>
          <p>
            - Türk kültürünü koruma ve yaşatma misyonunu benimseyerek gençleri
            bu kültürle buluşturmak.
          </p>
          <p>
            - Toplum bilincini güçlendirmek, birlik ve beraberlik ruhunu
            pekiştirmek.
          </p>
          <p>
            - Eğitimde kaliteyi ön planda tutarak gençlerin akademik
            başarılarına katkıda bulunmak ve karakter gelişimlerine destek
            olmak.
          </p>
          <p>
            <b>Çalışma Prensiplerimiz:</b>
          </p>
          <p>- Milli değerlere bağlılık ve vatan sevgisi.</p>
          <p>- Eğitimde kalite, özgünlük ve yenilikçilik.</p>
          <p>- Toplum bilinci ve birlik ruhu.</p>
          <p>
            - Gençlerin liderlik potansiyelini destekleme ve onlara fırsat
            eşitliği sağlama.
          </p>
          <p>
            Turan Ocakları Eğitim ve Kültür Vakfı olarak, bu misyon ve
            prensipler doğrultusunda Türk gençliğini, milli bir bilinçle
            donatarak, onları ülkelerine hizmet eden, kültürel mirasa sahip
            çıkan, liderlik yetenekleri gelişmiş bireyler olarak yetiştirmeyi
            kendimize görev ediniriz.
          </p>
        </div>
      </div>
    </PageLayouts>
  );
};

export default Hakkimizda;
