import React, { useEffect, useState } from "react";
import {
  useGetNewsDetailsQuery,
  useUpdateNewsMutation,
} from "../../../redux/api/newsApi";
import { useParams } from "react-router-dom";
import { Editor } from "primereact/editor";
import {toast} from 'react-hot-toast'
const CreateNews = () => {
  const [update, { isSuccess, error, isLoading: updateLoading }] = useUpdateNewsMutation();

  const { slug } = useParams();
  const { data: news } = useGetNewsDetailsQuery(slug);

  const [data, setData] = useState({});
  const [previewImage, setPreviewImage] = useState("");

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const onChangeImage = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setData({ ...data, image: reader.result });
        setPreviewImage(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const submitHandler = () => {
    update(data);
  };

  useEffect(() => {
    if (news) {
      setData({
        title: news?.news?.title,
        subtitle: news?.news?.subtitle,
        content: news?.news?.content,
        image: news?.news?.image,
        id: news?.news?._id
      });
      setPreviewImage(news?.news?.image?.url);
    }
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Haber Güncellendi.");
    }
  }, [news, error, isSuccess]);
  return (
    <div className="container mt-5">
      <div className="row ">
        <h1>Haber Güncelle</h1>
        <div className="col ">
          <div className="mb-3">
            <label htmlFor="title" className="form-label">
              Haber Başlığı
            </label>
            <input
              type="text"
              className="form-control"
              name="title"
              placeholder="Haber başlığı"
              value={data?.title}
              onChange={onChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="subtitle" className="form-label">
              Haber Altbaşlığı
            </label>
            <input
              type="text"
              className="form-control"
              name="subtitle"
              placeholder="Haber altbaşlığı"
              value={data?.subtitle}
              onChange={onChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="content" className="form-label">
              Haber İçeriği
            </label>
            <Editor
              value={data?.content}
              onTextChange={(e) => setData({ ...data, content: e.htmlValue })}
              style={{ height: "320px" }}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="image" className="form-label">
              Haber Kapak Resmi
            </label>
            <input
              type="file"
              className="form-control"
              name="image"
              onChange={onChangeImage}
            />
          </div>
          {previewImage !== "" && (
            <img
              src={previewImage}
              alt=""
              className="rounded-2"
              style={{ width: "120px", height: "120px" }}
            />
          )}
          <div className="mb-3 d-flex justify-content-end">
            <button className="btn btn-primary" disabled={updateLoading} onClick={() => submitHandler()}>
              {updateLoading ? "Güncelleniyor..." : "Güncelle"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNews;
