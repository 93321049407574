import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://to-fullstack.onrender.com/api", credentials: "include" }),
  tagTypes:["News"],
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => `/news`,
      providesTags: ["News"]
    }),
    getNewsDetails: builder.query({
      query: (slug) => `/news/${slug}`,
      providesTags: ["News"]
    }),
    createNews: builder.mutation({
      query(body) {
        return {
          url: "/news",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["News"]
    }),
    updateNews: builder.mutation({
      query(body) {
        return {
          url: `/news/${body?.id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["News"]
    }),
    deleteNews: builder.mutation({
      query(id) {
        return {
          url: `/news/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["News"]
    }),
  }),
});

export const {
  useGetNewsQuery,
  useGetNewsDetailsQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useDeleteNewsMutation
} = newsApi;
