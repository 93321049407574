import React from "react";

const NewsItem = ({ news }) => {
  return (
    <div className="col-md-6" style={{ marginBottom: "20px" }}>
      <a href={`/haber/${news?.slug}`}>

        <img
          src={news?.image?.url}
          alt=""
          className="arh ImageLazyLoader"
          data-resim-yolu=""
        />

        <div className="art">{new Date(news?.createdAt).toLocaleDateString("TR-tr", {
          month: "long",
          day: "numeric",
          year: "numeric"
        })}</div>
        <div className="arb">{news?.title}</div>
        <div className="ard">{news?.subtitle}</div>
      </a>
    </div>
  );
};

export default NewsItem;
