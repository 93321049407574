import React from "react";
import RightSidebar from "../../layouts/RightSidebar";

const Kurban = () => {
  return (
    <div class="page-content">
      <div class="container  g-0">
        <div class="row g-0">
          <div class="col-lg-8 col-md-12">
            <div id="content-dinamik-title">
              <div id="content-title-type">
                <a style={{color:"#c4b084"}} href="https://turanocaklari.com/">
                  HABERLER
                </a>
              </div>
              <div id="content-title-hepsi"></div>
            </div>

            <div id="detay-content" style={{width:"100%"}}>
              <div class="blog-media">
                <img
                  class="HaberResimYukleme"
                  data-resim-yolu=""
                  loading="lazy"
                  style={{width:"100%"}}
                  src="/img/turan-ocaklari-basin-1-1_4.png"
                />
              </div>
              <div class="blog-content">
                <div id="text-description">
                  <div class="bright-text">
                    <div id="haber-detay-baslik">
                      <div
                        id="haber-detay-saat"
                        style={{display:"flex", justifyContent:"space-between"}}
                      >
                        <div
                          id="haber-detay-saat-dakika"
                          style={{display:"inline-block"}} 
                        >
                          {" "}
                          17 Ocak 2024
                        </div>

                        <div class="mobile-social-media">
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://www.facebook.com/tuglar/"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_facebook.png" />
                          </a>
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://www.instagram.com/turanocaklarii/"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_instagram.png" />
                          </a>
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://x.com/turanocaklarii"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_twitter.png" />
                          </a>
                        </div>
                      </div>
                      Regaip Kandili'miz Kutlu Olsun
                    </div>
                    <div id="haber-detay-golge">
                      <div id="haber-detay-ozet">
                        Filistin için dualarımız, umudumuz ve dayanışmamız hiç
                        bitmeyecek.
                      </div>
                    </div>

                    <div id="haber-detay-aciklama">
                      <p>
                        Bu mübarek Regaip Kandili'nde yüreğimiz, Filistin
                        halkına yönelik duyduğumuz derin endişe ve dayanışma ile
                        çarpmaktadır. Tarihimiz boyunca eşsiz kandiller, birlik
                        ve beraberliğimizi pekiştirmenin yanı sıra, mazlumların
                        sesine kulak verme ve yardım elini uzatma fırsatlarını
                        da içermiştir.
                      </p>
                      <p>
                        Filistin halkının maruz kaldığı zorluklar, her birimizin
                        kalbinde derin yaralar açmaktadır. Biz, Turan Ocakları
                        ailesi olarak, bu mübarek gecede Filistinli
                        kardeşlerimizin yanında olduğumuzu bir kez daha
                        haykırmak, acılarını paylaşmak ve karanlık günlerinde
                        yanlarında durmak istiyoruz.
                      </p>
                      <p>
                        Zulme karşı duran, adaleti savunan ve insan haklarına
                        saygı gösteren bir toplum olarak, Filistin halkının
                        haklı mücadelesine omuz vermek bir görevdir. Onların
                        acıları bizim acılarımızdır, dualarımız onlarladır.
                      </p>
                      <p>
                        Bu Regaip Kandili vesilesiyle Filistin halkına, kalpten
                        gelen bir dayanışma mesajı iletmek istiyoruz. Umuyoruz
                        ki, bu mübarek gecede yapılan dualar, Filistin'de
                        barışın, özgürlüğün ve adaletin hakim olmasına vesile
                        olur.
                      </p>
                      <p>
                        Dualarımız, Filistin halkının yanı sıra tüm mazlumların
                        yüreklerine dokunacak, huzur ve barışın hakim olduğu bir
                        dünyanın inşasına katkı sağlayacaktır.
                      </p>
                      <p>
                        Bu kutsal gecede tüm Müslüman kardeşlerimize, ülkemize
                        ve bütün insanlığa barış, sağlık ve huzur dilerim.
                      </p>
                      <p>Saygılarımla,</p>
                      <p>
                        <b>
                          <i>
                            Turan Ocakları Eğitim ve Kültür Vakfı Genel Başkanı
                          </i>
                        </b>
                      </p>
                      <p>
                        <b>
                          <i>Abdulkadir KAPLAN</i>
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12"><RightSidebar /></div>
        </div>
      </div>
    </div>
  );
};

export default Kurban;
