import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from '../../layouts/MetaData'

const Misyon = () => {
  return (
    <PageLayouts>
      <MetaData title={"Misyon"} />
      <div id="content-dinamik-title">
        <div id="content-title-type">MİSYON</div>
      </div>
      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="baskanlik-content">
          <p>
            <b>Turan Ocakları Eğitim ve Kültür Vakfı "MİSYONUMUZ"</b>
          </p>
          <p>
            Vakfımız, Türk milletinin kahramanlık dolu tarihine, kültür
            zenginliğine ve milli değerlerine olan bağlılığı temel alarak, genç
            nesillerin nitelikli bir şekilde yetişmesine katkıda bulunmayı
            amaçlar. Misyonumuz, şu temel prensiplere dayanmaktadır:
          </p>
          <p>
            <b>1.</b> *Vatansever Liderlik:* Türk gençliğini vatanseverlikle
            yoğrulmuş, liderlik vasıflarına sahip bireyler olarak yetiştirmeyi
            hedefleriz. Bu kapsamda, öğrencilere sağlanan burslarla sadece
            akademik başarıyı değil, aynı zamanda vatan sevgisi ve sorumluluk
            bilinci kazandırmayı amaçlarız.
          </p>
          <p>
            <b>2.</b> *Türk Kültürünü Yaşatma:* Vakıf olarak, Türk kültürünü
            koruma ve yaşatma misyonunu benimseriz. Özel projeler, etkinlikler
            ve kültürel faaliyetler aracılığıyla gençlerin Türk milletinin
            zengin kültür mirasıyla buluşmalarını sağlamayı amaçlarız.
          </p>
          <p>
            <b>3.</b> *Toplum Bilincini Güçlendirme:* Milli bir duruşla, toplum
            bilincini güçlendirmeyi önemseriz. Vakfımızın programları ve
            etkinlikleri, birlik ve beraberlik ruhunu pekiştirmeyi ve Türk
            milletinin ortak değerlerine vurgu yapmayı amaçlar.
          </p>
          <p>
            <b>4.</b>*Eğitimde Kalite:* Eğitim alanında öncü olma hedefimizi
            gerçekleştirmek için, öğrencilere sunduğumuz burslarla birlikte
            özgün eğitim programları ve projeler geliştiririz. Böylece,
            gençlerin sadece akademik değil, aynı zamanda karakter gelişimine de
            katkıda bulunmayı amaçlarız.
          </p>
          <p>
            Turan Ocakları Eğitim ve Kültür Vakfı olarak, bu misyonlar
            doğrultusunda Türk gençliğini milli bir bilinçle donatarak, onları
            toplumlarına liderlik edebilen, kültürel değerlere sahip çıkan
            bireyler olarak yetiştirmeyi kendimize görev ediniriz.
          </p>
        </div>
      </div>
    </PageLayouts>
  );
};

export default Misyon;
