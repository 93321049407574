import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from "../../layouts/MetaData";
import NewsItem from "./NewsItem";
import { useGetNewsQuery } from "../../redux/api/newsApi";

const News = () => {
  const {data: news, isLoading, error} = useGetNewsQuery();

  return (
    <PageLayouts>
      <MetaData title={"Mesajlar"} />
      <div id="content-dinamik-title">
        <div id="content-title-type" style={{ width: "60%" }}>
          Haberler
        </div>
      </div>
      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="baskanlik-content">
          <div className="baskanlik-anasayfa-alt-haber">
            <div className="row">
              {news?.news?.map((item) => (
                <NewsItem news={item} key={item?._id} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  );
};

export default News;
