import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from "../../layouts/MetaData";

const Iletisim = () => {
  return (
    <PageLayouts>
      <MetaData title={"İletişim"} />
      <div id="content-dinamik-title">
        <div id="content-title-type" style={{ width: "50%" }}>
          Turan Ocakları Eğitim ve Kültür Vakfı
        </div>
        <div className="baskanlik-header">İLETİŞİM</div>
      </div>
      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="baskanlik-content">
          <table
            className="table table-striped dataTable no-footer"
            style={{ marginTop: "30px" }}
          >
            <tr className="even">
              <td style={{ fontWeight: 600 }}>ADRES</td>
              <td>:</td>
              <td>Genel Merkez: Cinnah Caddesi No: 31/25 Çankaya/Ankara</td>
            </tr>
            <tr className="odd">
              <td style={{ fontWeight: 600 }}>TELEFON</td>
              <td>:</td>
              <td>
                {" "}
                <a href="tel=+905362006038" style={{ color: "black" }}>
                  0 536 200 6038
                </a>
              </td>
            </tr>
            <tr className="even">
              <td style={{ fontWeight: 600, color: "black" }}>WhatsApp</td>
              <td>:</td>
              <td>
                {" "}
                <a href="https://wa.me/905362006038" style={{ color: "black" }}>
                  0 536 200 6038
                </a>
              </td>
            </tr>
            <tr className="even">
              <td style={{ fontWeight: 600 }}>E-POSTA</td>
              <td>:</td>
              <td>info@turanocaklari.org.tr</td>
            </tr>
          </table>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6122.087477762267!2d32.857809!3d39.895653!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34f9c6761ccaf%3A0x941ae0696f5cfaeb!2s%C3%87ankaya%2C%20Cinnah%20Cd.%20No%3A31%20D%3A25%2C%2006690%20%C3%87ankaya%2FAnkara!5e0!3m2!1str!2str!4v1712530654702!5m2!1str!2str"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </PageLayouts>
  );
};

export default Iletisim;
