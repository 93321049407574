import React, { useEffect, useState } from "react";
import { FaArrowTurnDown } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { useGetMeQuery } from "../redux/api/userApi";
import { useSelector } from "react-redux";
import { useLazyLogoutQuery } from "../redux/api/authApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useGetMeQuery();
  const [logout, { isSuccess }] = useLazyLogoutQuery();

  const navigate = useNavigate();

  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState({
    hover: false,
    number: 0,
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    hover: false,
    number: 0,
    hidden: true,
  });
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const [activeUrl, setActiveUrl] = useState("");

  const navbarItems = [
    {
      url: "/",
      text: "ANASAYFA",
      dropdown: false,
    },
    {
      url: "/kurumsal",
      text: "KURUMSAL",
      dropdown: true,
      dropdownNumber: 1,
      dropdownItems: [
        {
          url: "/baskan/ozgecmis",
          text: "Genel Başkan",
        },
        {
          url: "/baskan/yonetim-kurulu",
          text: "Yönetim Kurulu",
        },
        {
          url: "/baskan/denetim-kurulu",
          text: "Denetim Kurulu",
        },
        {
          url: "https://turanocaklari.com",
          text: "Haberler",
        },
        {
          url: "/baskan/mesajlar",
          text: "Mesajlar",
        },
        {
          url: "/baskan/iletisim",
          text: "İletişim",
        },
      ],
    },
    {
      text: "TÜZÜK",
      dropdown: true,
      dropdownNumber: 2,
      dropdownItems: [
        {
          url: "/tüzük",
          text: "Tüzük",
        },
        {
          url: "/vizyon",
          text: "Vizyon",
        },
        {
          url: "/misyon",
          text: "Misyon",
        },
        {
          url: "/yakinda",
          text: "Kararlar",
        },
        {
          url: "/yakinda",
          text: "Toplantı Raporları",
        },
      ],
    },
    {
      text: "ETKİNLİKLER",
      dropdown: true,
      dropdownNumber: 3,
      dropdownItems: [
        {
          url: "/etkinlikler/bagis",
          text: "Bağış",
        },
        {
          url: "/yakinda",
          text: "Vakıf Etkinlikleri",
        },
        {
          url: "/yakinda",
          text: "Basın Açıklamaları",
        },
        {
          url: "/yakinda",
          text: "Tanışma Etkinlikleri",
        },
        {
          url: "/yakinda",
          text: "Resmi Ziyaretler",
        },
      ],
    },
    {
      text: "GÜNDEM",
      dropdown: true,
      dropdownNumber: 4,
      dropdownItems: [
        {
          url: "/yakinda",
          text: "Vakıf Gündemi",
        },
        {
          url: "/yakinda",
          text: "Ulusal Gündem",
        },
        {
          url: "https://turanocaklari.com",
          text: "Turan Ocakları Haber",
        },
      ],
    },
    {
      text: "ÜYELİK BAŞVURUSU",
      dropdown: true,
      dropdownNumber: 5,
      dropdownItems: [
        {
          url: "/kayit",
          text: "Üyelik Başvurusu",
        },
        {
          url: "/yakinda",
          text: "Üye Bilgilendirme",
        },
      ],
    },
    {
      text: "MEDYA",
      dropdown: true,
      dropdownNumber: 6,
      dropdownItems: [
        {
          url: "/yakinda",
          text: "Fotoğraf",
        },
        {
          url: "/yakinda",
          text: "Video",
        },
      ],
    },
  ];

  useEffect(() => {
    setActiveUrl(window.location.pathname);
    if (isSuccess) {
      toast.success("Çıkış Yapıldı.");
      navigate("/");
      window.location.reload();
    }
  }, [isSuccess]);
  const [showSidebar, setShowSidebar] = useState(false);
  const handleMenuToggle = () => {
    setShowSidebar(!showSidebar);
  };
  const logoutHandler = () => {
    logout();
  };

  return (
    <header>
      <div id="header-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <a href="/">
                <div id="header-banner"></div>
              </a>
            </div>
          </div>
        </div>

        <div id="header-container-menu">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div id="header-menu-bar">
                  <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                          <li className="nav-item active">
                            <a className="nav-link" href="/">
                              ANASAYFA
                            </a>
                          </li>
                          {user?.data?.role === "admin" && (
                            <li className="nav-item ">
                              <a
                                className="nav-link"
                                href="/admin/haberleri-getir"
                              >
                                ADMİN
                              </a>
                            </li>
                          )}
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              KURUMSAL
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a
                                  className="dropdown-item"
                                  href="/baskan/ozgecmis"
                                >
                                  Genel Başkan
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a
                                  className="dropdown-item"
                                  href="/baskan/yonetim-kurulu"
                                >
                                  Yönetim Kurulu
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a
                                  className="dropdown-item"
                                  href="/baskan/denetim-kurulu"
                                >
                                  Denetim Kurulu
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a
                                  className="dropdown-item"
                                  href="https://www.turanocaklari.com"
                                >
                                  Haberler
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a
                                  className="dropdown-item"
                                  href="/baskan/mesajlar"
                                >
                                  Mesajlar
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a
                                  className="dropdown-item"
                                  href="/baskan/iletisim"
                                >
                                  İletişim
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              TÜZÜK
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a
                                  className="dropdown-item"
                                  href="/tuzuk/tuzuk"
                                >
                                  Tüzük
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a
                                  className="dropdown-item"
                                  href="/tuzuk/vizyon"
                                >
                                  Vizyon
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a
                                  className="dropdown-item"
                                  href="/tuzuk/misyon"
                                >
                                  Misyon
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/yakinda">
                                  Kararlar
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/yakinda">
                                  Toplantı Raporları
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              ETKİNLİKLER
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a
                                  className="dropdown-item"
                                  href="/etkinlikler/bagis"
                                >
                                  Bağış Etkinlikleri
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/yakinda">
                                  Vakıf Etkinlikleri
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/yakinda">
                                  Basın Açıklamaları
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/yakinda">
                                  Tanışma Etkinlikleri
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/yakinda">
                                  Resmi Ziyaretler
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              GÜNDEM
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/yakinda">
                                  Vakıf Gündemi
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/yakinda">
                                  Ulusal Gündem
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a
                                  className="dropdown-item"
                                  href="https://www.turanocaklari.com"
                                >
                                  Turan Ocakları Haber
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              ÜYELİK BAŞVURUSU
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                              style={{ marginLeft: "100%" }}
                            >
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/kayit">
                                  Üyelik Başvurusu
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/yakinda">
                                  Üye Bilgilendirme
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              MEDYA
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                              style={{ marginLeft: "100%" }}
                            >
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/yakinda">
                                  Fotoğraf
                                </a>
                              </li>
                              <li>
                                <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                                <a className="dropdown-item" href="/yakinda">
                                  Video
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="header-container-mobile">
        <div className="container">
          <div className="row">
            <div className="col-md-12 g-0" style={{ marginBottom: "-20px" }}>
              <div id="header-banner">
                <div id="mobileHamburgerMenu" className="navMobile">
                  <a
                    href="javascript:void(0)"
                    onClick={handleMenuToggle}
                    id="navButton"
                  >
                    <div></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="MobileMenuAlani" style={{ position: "relative" }}>
          <div
            className="sidebar"
            style={{
              display: `${showSidebar ? "block" : "none"}`,
              transition: "ease .5s all",
            }}
          >
            <div className="d-flex align-items-center justify-content-between ">
              <a href="/">
                <img src="/img/kurumsal.png" style={{ width: "70%" }} />
              </a>
              <IoClose
                size={32}
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => setShowSidebar(!showSidebar)}
              />
            </div>

            <div className="mt-5">
              {/* Mobile Navigation */}
              <div
                style={{ maxHeight: "600px", overflow: "auto" }}
                className={`mobile-sidebar d-lg-none position-absolute w-100 z-50 transition-all ${
                  showSidebar ? "top-28" : "-top-500px"
                }`}
              >
                <div
                  className={`d-flex flex-column pe-3 ${
                    showSidebar ? "d-flex" : "d-none"
                  }`}
                >
                  {navbarItems?.map((item) =>
                    item?.dropdown ? (
                      <div key={item.dropdownNumber}>
                        <div className="d-flex flex-column">
                          <div
                            className="d-flex justify-content-between align-items-center py-3"
                            onClick={() =>
                              setIsDropdownOpen({
                                hover: !isDropdownOpen.hover,
                                number: item.dropdownNumber,
                                hidden: !isDropdownOpen.hidden,
                              })
                            }
                          >
                            <a className={`ps-4 text-xl text-white`} href="#">
                              {item.text}
                            </a>
                            <FaArrowTurnDown
                              color="white"
                              className="cursor-pointer"
                            />
                          </div>
                          <div
                            style={{
                              width: "100%",
                              background: "#fff",
                              height: "1px",
                              marginLeft: "10px",
                            }}
                          ></div>
                        </div>
                        <div
                          className={`d-flex flex-column transition-all bg-white ms-3 ${
                            isDropdownOpen.hover &&
                            isDropdownOpen.number === item.dropdownNumber
                              ? "h-auto"
                              : "h-0"
                          }`}
                        >
                          {item.dropdownItems?.map((dItem) => (
                            <a
                              href={dItem.url}
                              className={`ps-4 py-3 text-lg border-bottom ${
                                !isDropdownOpen.hidden &&
                                isDropdownOpen.hover &&
                                isDropdownOpen.number === item.dropdownNumber
                                  ? ""
                                  : "d-none"
                              }`}
                              key={dItem.text}
                            >
                              {dItem.text}
                            </a>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex flex-column">
                        <a
                          href={item.url}
                          className="ps-4 py-3 text-xl text-white"
                          key={item.text}
                        >
                          {item.text}
                        </a>
                        <div
                          className=""
                          style={{
                            width: "100%",
                            background: "#fff",
                            height: "1px",
                            marginLeft: "10px",
                          }}
                        ></div>
                      </div>
                    )
                  )}
                  {user?.data?.role === "admin" && (
                    <div
                      className="d-flex flex-column"
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        href="/admin/haberleri-getir"
                        className="ps-4 py-3 text-xl text-white"
                      >
                        ADMİN
                      </a>
                      <div
                        className=""
                        style={{
                          width: "100%",
                          background: "#fff",
                          height: "1px",
                          marginLeft: "10px",
                        }}
                      ></div>
                    </div>
                  )}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
