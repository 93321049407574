import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

import Home from "./components/pages/Home";
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import ComingSoon from "./components/pages/ComingSoon";
import NewsDetails from "./components/pages/NewsDetails";
import Ozgecmis from "./components/pages/baskan/Ozgecmis";
import Mesajlar from "./components/pages/baskan/Mesajlar";
import Iletisim from "./components/pages/baskan/Iletisim";
import YonetimKurulu from "./components/pages/baskan/YonetimKurulu";
import Tuzuk from "./components/pages/tuzuk/Tuzuk";
import Vizyon from "./components/pages/tuzuk/Vizyon";
import Misyon from "./components/pages/tuzuk/Misyon";
import Bagis from "./components/pages/etkinlik/Bagis";
import Hakkimizda from "./components/pages/tuzuk/Hakkimizda";
import News from "./components/pages/haber/News";
import GetAllNews from "./components/pages/admin/news/GetAllNews";
import CreateNews from "./components/pages/admin/news/CreateNews";
import EditNews from "./components/pages/admin/news/EditNews";
import AdminLayouts from "./components/layouts/AdminLayouts";
import GetAllUsers from "./components/pages/admin/users/GetAllUsers";
import EditUser from "./components/pages/admin/users/EditUsers";
import Login from "./components/pages/auth/Login";
import Bayram from "./components/pages/haber/Bayram";
import Kurban from "./components/pages/haber/Kurban";
import Detay1 from "./components/pages/haber/Detay1";
import Detay2 from "./components/pages/haber/Detay2";

import "./App.css";
import "./css/fontawesome-all.min.css";
import "./css/tbmm-yeni-tasarim.css";
import Register from "./components/pages/auth/Register";
import DenetimKurulu from "./components/pages/baskan/DenetimKurulu";

function App() {
  return (
    <Router>
      <Header />
      <Toaster position="top-right"  />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/giris" element={<Login />} />
        <Route path="/kayit" element={<Register />} />
        <Route path="/yakinda" element={<ComingSoon />} />
        <Route path="/haberler" element={<News />} />
        <Route path="/haber/:slug" element={<NewsDetails />} />

        <Route path="/haber/bayram" element={<Bayram />} />
        <Route path="/haber/kurban-bayrami" element={<Kurban />} />
        <Route path="/haber/detay1" element={<Detay1 />} />
        <Route path="/haber/detay2" element={<Detay2 />} />

        <Route path="/baskan/ozgecmis" element={<Ozgecmis />} />
        <Route path="/baskan/yonetim-kurulu" element={<YonetimKurulu />} />
        <Route path="/baskan/denetim-kurulu" element={<DenetimKurulu />} />
        <Route path="/baskan/mesajlar" element={<Mesajlar />} />
        <Route path="/baskan/iletisim" element={<Iletisim />} />

        <Route path="/tuzuk/tuzuk" element={<Tuzuk />} />
        <Route path="/tuzuk/vizyon" element={<Vizyon />} />
        <Route path="/tuzuk/misyon" element={<Misyon />} />
        <Route path="/tuzuk/hakkimizda" element={<Hakkimizda />} />

        <Route path="/etkinlikler/bagis" element={<Bagis />} />

        <Route path="/admin">
          <Route path="haberleri-getir" element={<AdminLayouts><GetAllNews /></AdminLayouts>} />
          <Route path="haber-olustur" element={<AdminLayouts><CreateNews /></AdminLayouts>} />
          <Route path="haber-duzenle/:slug" element={<AdminLayouts><EditNews /></AdminLayouts>} />

          <Route path="kullanicilari-getir" element={<AdminLayouts><GetAllUsers /></AdminLayouts>} />
          <Route path="kullanici-duzenle/:id" element={<AdminLayouts><EditUser /></AdminLayouts>} />
        </Route>

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
