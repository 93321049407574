import React from "react";
import RightSidebar from "../layouts/RightSidebar";
import MetaData from '../layouts/MetaData'

const ComingSoon = () => {
  return (
    <div className="page-content">
      <MetaData title={"Yakında"} />
      <div className="container  g-0">
        <div className="row g-0">
          <div className="col-lg-8 col-md-12">
            <div id="content-dinamik-title">
              <div id="content-title-type" style={{ width: "60%" }}>
                Yakında Sizlerle
              </div>
              <div
                id="anasayfa-link"
                style={{
                  float: "right",
                  fontSize: "16px",
                  height: "70px",
                  color: "#c4b084",
                  fontFamily: "Raleway",
                  fontWeight: 100,
                  letterSpacing: "2px",
                  display: "flex",
                  marginRight: "20px",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                Ana Sayfa'ya Dön!
              </div>
            </div>

            <div className="blog-content tbmm-div-list alt-sayfa-content">
              <div className="baskanlik-content">
                <div className="baskanlik-baskan-ozgecmis">
                  <div style={{ float: "left", width: "100%" }}>
                    <img style={{ width: "100%" }} src="/img/16devlet.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
