import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from "../../layouts/MetaData";
import ModalComp from "../../layouts/Modal";

const YonetimKurulu = () => {
  const kuruluUye = [
    {
      fullname: "Abdulkadir KAPLAN",
      role: "Genel Başkan",
      img: "/img/kadir1-1.png",
    },
    {
      fullname: "Alptekin YÜREK",
      role: "Genel Sekreter",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "Berzan ÖNER",
      role: "Genel Merkez Sözcüsü",
      img: "/img/uyeler/berzan-oner.jpeg",
    },
    {
      fullname: "Aydın GÜNGOR",
      role: "Genel Başkan Yardımcısı",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "-",
      role: "Genel Başkan Yardımcısı",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "-",
      role: "Teşkilat Başkanı",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "-",
      role: "Hukuki İlişkiler Başkanı",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "-",
      role: "Sosyal Politikalar Başkanı",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "Ömer AYDIN",
      role: "Denetim Kurulu Başkanı",
      img: "/img/uyeler/omer-aydin.jpg",
    },
    {
      fullname: "Yusuf GENCELİ",
      role: "Tanıtım ve Medya Başkanı",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "Hüseyin KAPLAN",
      role: "Mali ve İdari İşler Başkanı",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "Abdussamed KAPLAN",
      role: "AR-GE Başkanı",
      img: "/img/bayrak-logo.jpeg",
    },
  ];
  
  return (
    <PageLayouts>
      <MetaData title={"Yönetim Kurulu"} />
      <div id="content-dinamik-title">
        <div id="content-title-type" style={{ width: "60%" }}>
          Yönetim Kurulu
        </div>
      </div>

      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="">
          <div className="baskanlik-anasayfa-alt-haber ">
            <div className="row gap-5 d-flex justify-content-center align-items-center">
              {kuruluUye?.map((uye) => (
                <div
                className="col-md-3 border rounded-2"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{ marginBottom: "20px" }}
              >
                  <img src={uye.img} alt="" className="kurul-card" />
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <h6 className="p-1 poppins-medium" style={{ color: "#1f1f1f", fontSize: "10px", marginTop: "15px"}}>{uye.fullname}</h6>
                    <span className="p-1 mb-3" style={{color: "#5c5c5c", fontSize: "12px"}}>{uye.role}</span>
                  </div>
                  {/* <ModalComp /> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  );
};

export default YonetimKurulu;
