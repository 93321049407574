import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
} from "../../../redux/api/userApi";

const GetAllUsers = () => {
  const { data: users, isLoading, error } = useGetAllUsersQuery();
  const [deleteUser, { isLoading: newsLoading, error: newsError, isSuccess }] =
    useDeleteUserMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Kullanıcı Silindi.");
    }
    if (newsError) {
      toast.error(newsError?.data?.message);
    }
  }, [newsError, isSuccess]);

  return (
    <>
      <h1>Kullanıcılar</h1>
      <table className="table table-striped table-bordered">
        <thead className="table-primary">
          <tr>
            <th>Ad-Soyad</th>
            <th>E-Posta</th>
            <th>Rol</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {users?.users?.length > 0 ? (
            users?.users?.map((item, index) => (
              <tr key={item._id}>
                <td>{item?.firstname + " " + item?.lastname}</td>
                <td>{item.email}</td>
                <td>{item.role}</td>
                <td>
                  <a
                    href={`/admin/kullanici-duzenle/${item?._id}`}
                    className="btn btn-primary btn-sm me-2"
                  >
                    Düzenle
                  </a>
                  <button
                    className="btn btn-danger btn-sm"
                    disabled={newsLoading}
                    onClick={() => deleteUser(item?._id)}
                  >
                    Sil
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                Hiç kullanıcı bulunamadı.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default GetAllUsers;
