import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from "../../layouts/MetaData";

const Bagis = () => {
  return (
    <PageLayouts>
      <MetaData title={"Bağış"} />
      <div id="content-dinamik-title">
        <div id="content-title-type" style={{ width: "60%" }}>
          Turan Ocakları Eğitim ve Kültür Vakfı
        </div>
        <div className="baskanlik-header">Bağış Etkinlikleri</div>
      </div>
      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="baskanlik-content">
          <div className="baskanlik-anasayfa-alt-haber">
            <div className="row">
              <div className="col-md-6" style={{ marginBottom: "20px" }}>
                <a href="https://www.mehmetcik.org.tr/vekaleten-kurban-bagisi">
                  <div
                    className="arh ImageLazyLoader"
                    data-resim-yolu=""
                    style={{
                      backgroundImage: "url('/img/etkinlikler/mehmetcik.jpg')",
                    }}
                  ></div>

                  <div className="art">Vekâleten Kurban Bağışı</div>
                  <div className="arb">TSK Mehmetçik Vakfı</div>
                  <div className="ard">
                    Modern tesislerde, hijyenik koşullarda,
                    <br />
                    Her kesimhanede görevlendirilen Vakıf personeli huzurunda,
                    <br />
                    Veteriner denetiminde,
                    <br />
                    Noter gözetiminde,
                    <br />
                    Din görevlisi nezaretinde,
                    <br />
                    Dini vasıflara uygun kurbanlıklar seçilerek yapılmaktadır.
                    <br />
                    Kesilen kurbanların bir bölümü tüm şehit yakınları ve
                    gaziler ile kurban bağışında bulunan bağışçılarımıza
                    üretimini müteakip kurban etinden yapılmış 750 gr. konserve
                    kavurma olarak gönderilmektedir. Kurban etinin dağıtılamayan
                    kısmı ise ekonomik olarak değerlendirilerek yardım planına
                    dahil şehit yakınları ve gazi Mehmetçiklerimiz ile
                    çocuklarına yardım için kullanılmaktadır.
                    <br />
                    Kurban bayramı dönemi dışında gerçekleşen vekâleten adak
                    kurban bağışına ilişkin olarak sürekli bir organizasyonu
                    gerçekleştirmediğimiz için adak kurbanı ile ilgili
                    müracaatların bu organizasyonu gerçekleştiren kurumlara
                    yapılması gerekmektedir.
                  </div>
                </a>
              </div>
              <div className="col-md-6" style={{ marginBottom: "20px" }}>
                <a href="https://www.losev.org.tr/bagis/kurban.html">
                  <div
                    className="arh ImageLazyLoader"
                    data-resim-yolu=""
                    style={{
                      backgroundImage: "url('/img/etkinlikler/losev.jpeg')",
                    }}
                  ></div>

                  <div className="art">Vekâleten Kurban Bağışı</div>
                  <div className="arb">Lösev (Lösemili Çocuklar Vakfı)</div>
                  <div className="ard">
                    Diyanet İşleri Başkanlığı Yüce Allah’a yakınlaşmak amacıyla
                    gerçekleşen kurban ibadetlerinin mali bir ibadet olduğu için
                    vekâleten yapılabileceğine dair cevaz veriyor ve “Dinimiz
                    gereğince kurbanın rüknü; kurban kesilerek kanın
                    akıtılmasıdır” şeklinde açıklıyor.
                    <br />
                    LÖSEV olarak 14 yıldır sizin için et kombinalarına gidiyor,
                    vekâleten kurban kesimi yaptırıyor, etini lösemili ve
                    kanserli çocuklarımıza, ailelerine ve yetişkin kanser
                    hastalarına dağıtıyoruz. Sözel ya da yazılı verebileceğiniz
                    vekâlet, kurban ibadeti için yeterlidir. Kesimler kurbanlık
                    vasfına haiz küçükbaş veya büyükbaş olarak en modern
                    kesimhanelerde, dini esaslara uygun şekilde Kurban
                    Bayramı’nın ilk üç gününde yerine getirilirken kurbanın eti,
                    derisi ve tüm organları da değerlendirilmektedir.
                    <br />
                    Kesilen kurban etlerinin bir kısmını dinlendirerek bayramdan
                    hemen sonra, diğer kısmını ise kesen kurumlara emanet ederek
                    12 ay boyunca vakumlanmış ambalajlarda taze et ve et
                    ürünleri şeklinde lösemili ve kanserli çocuklarımızla
                    beraber ailelerine ve ihtiyacı olan yetişkin kanser
                    hastalarına dağıtıyoruz.
                    <br />
                  </div>
                </a>
              </div>
              <div className="col-md-6" style={{ marginBottom: "20px" }}>
                <a href="https://www.darussafaka.org/bagis/bayram-bagisi">
                  <div
                    className="arh ImageLazyLoader"
                    data-resim-yolu=""
                    style={{
                      backgroundImage:
                        "url('/img/etkinlikler/darusaffaka.jpg')",
                    }}
                  ></div>

                  <div className="art">Bayram Bağışı</div>
                  <div className="arb">
                    Kurban Bayramı bağışlarınız Darüşşafaka'ya
                  </div>
                  <div className="ard">
                    Öğrencilerimizin eğitim ihtiyaçlarını karşılamak için
                    kullanacağımız Kurban Bayramı bağışı için bu sene
                    önerdiğimiz kurban hisse bedeli 10.000 TL'dir. Ancak
                    dilediğiniz tutarda yapacağınız her bağış bizim için çok
                    kıymetlidir.
                    <b> Vekaleten kesimli kurban bağışı kabul edemiyoruz!</b>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  );
};

export default Bagis;
