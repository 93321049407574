import React from 'react'
import {Helmet} from 'react-helmet'
const MetaData = ({title}) => {
  return (
    <Helmet>
        <title>Turan Ocakları Eğitim ve Kültür Vakfı - {title}</title>
        <meta name={`Turan Ocakları Eğitim ve Kültür Vakfı - ${title}`} />
    </Helmet>
  )
}

export default MetaData