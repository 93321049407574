import React from "react";
import RightSidebar from "../../layouts/RightSidebar";

const Kurban = () => {
  return (
    <div class="page-content">
      <div class="container  g-0">
        <div class="row g-0">
          <div class="col-lg-8 col-md-12">
            <div id="content-dinamik-title">
              <div id="content-title-type">
                <a
                  style={{ color: "#c4b084" }}
                  href="https://turanocaklari.com/"
                >
                  HABERLER
                </a>
              </div>
              <div id="content-title-hepsi"></div>
            </div>

            <div id="detay-content" style={{ width: "100%" }}>
              <div class="blog-media">
                <img
                  class="HaberResimYukleme img-fluid"
                  data-resim-yolu=""
                  loading="lazy"
                  style={{ width: "100%" }}
                  src="/img/turan-ocaklari-basin-1-1_4.png"
                />
              </div>
              <div class="blog-content">
                <div id="text-description">
                  <div class="bright-text">
                    <div id="haber-detay-baslik">
                      <div
                        id="haber-detay-saat"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          id="haber-detay-saat-dakika"
                          style={{ display: "inline-block" }}
                        >
                          {" "}
                          17 Ocak 2024
                        </div>

                        <div class="mobile-social-media">
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://www.facebook.com/tuglar/"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_facebook.png" />
                          </a>
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://www.instagram.com/turanocaklarii/"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_instagram.png" />
                          </a>
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://x.com/turanocaklarii"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_twitter.png" />
                          </a>
                        </div>
                      </div>
                      Turan Ocakları Eğitim ve Kültür Vakfı, Türk Milli
                      Değerlerine Bağlılığını Yenilenen Web Sitesi ile
                      Güçlendiriyor
                    </div>
                    <div id="haber-detay-golge">
                      <div id="haber-detay-ozet">
                        Turan Ocakları Eğitim ve Kültür Vakfı, Türk Milli
                        Değerlerine Bağlılığını Yenilenen Web Sitesi ile
                        Güçlendiriyor
                      </div>
                    </div>

                    <div id="haber-detay-aciklama">
                      <p>
                        Turan Ocakları Eğitim ve Kültür Vakfı, Türk milletinin
                        zengin tarihine, kültürüne ve milli değerlerine olan
                        derin sevgi ve bağlılıkla öne çıkan bir sivil toplum
                        kuruluşudur. Vakıf, genç nesillerin milli kimlikleriyle
                        bütünleşmelerine, liderlik vasıflarını geliştirmelerine
                        ve vatanlarına daha fazla hizmet etmelerine odaklanarak
                        eğitim ve kültür alanlarında önemli bir rol
                        üstlenmektedir.
                      </p>
                      <p>
                        Vakfımız, Türk milletinin zengin tarihini ve kültür
                        mirasını gelecek kuşaklara taşımayı, gençleri milli
                        değerlerle buluşturarak Türk kültürünü geliştirmeyi,
                        eğitimde öncü projelerle toplumun bilinç düzeyini
                        yükseltmeyi ve birlik beraberlik ruhunu güçlendirmeyi
                        misyon edinmiştir. Bu doğrultuda vizyonumuz, Türk
                        gençliğini vatansever, bilinçli ve liderlik vasıflarına
                        sahip bireyler olarak yetiştirirken, Türk kültürünü
                        koruma ve yaşatma misyonunu benimseyerek gençleri bu
                        kültürle buluşturmayı hedefler.
                      </p>
                      <p>
                        Vakfımızın çalışma prensipleri, milli değerlere
                        bağlılık, eğitimde kalite ve yenilikçilik, toplum
                        bilinci ve birlik ruhu ile gençlerin liderlik
                        potansiyelini destekleme odaklıdır. Bu çerçevede,
                        yenilenen web sitemiz, gençlerin milli bilinçlerini
                        güçlendirmek, eğitimde öncü projelerle katkı sağlamak ve
                        birlikte büyümek amacıyla daha erişilebilir, interaktif
                        ve zengin bir platform sunmak üzere tasarlanmıştır.
                      </p>
                      <p>
                        Turan Ocakları Eğitim ve Kültür Vakfı olarak, Türk
                        gençliğini milli bir bilinçle donatarak, onları
                        ülkelerine hizmet eden, kültürel mirasa sahip çıkan ve
                        liderlik potansiyellerini geliştirmiş bireyler olarak
                        yetiştirmeyi amaçlıyoruz.
                      </p>
                      <p>
                        <b>
                          <i>
                            Turan Ocakları Eğitim ve Kültür Vakfı Genel Başkanı
                          </i>
                        </b>
                      </p>
                      <p>
                        <b>
                          <i>Abdulkadir KAPLAN</i>
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kurban;
