import React, { useEffect } from "react";
import {
  useDeleteNewsMutation,
  useGetNewsQuery,
} from "../../../redux/api/newsApi";
import {toast} from 'react-hot-toast'

const GetAllNews = () => {
  const { data: news, isLoading, error } = useGetNewsQuery();
  const [deleteNews, { isLoading: newsLoading, error: newsError, isSuccess }] =
    useDeleteNewsMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Haber Silindi.");
    }
    if (newsError) {
      toast.error(newsError?.data?.message);
    }
  }, [newsError, isSuccess]);

  return (
    <table className="table table-striped table-bordered">
        <thead className="table-primary">
          <tr>
            <th>Başlık</th>
            <th>Tarih</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {news?.news?.length > 0 ? (
            news?.news?.map((item, index) => (
              <tr key={item._id}>
                <td>{item.title}</td>
                <td>
                  {new Date(item.createdAt).toLocaleDateString("tr-TR", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </td>
                <td>
                  <a
                    href={`/admin/haber-duzenle/${item?.slug}`}
                    className="btn btn-primary btn-sm me-2"
                  >
                    Düzenle
                  </a>
                  <button
                    className="btn btn-danger btn-sm"
                    disabled={newsLoading}
                    onClick={() => deleteNews(item?._id)}
                  >
                    Sil
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                Hiç haber bulunamadı.
              </td>
            </tr>
          )}
        </tbody>
      </table>
  );
};

export default GetAllNews;
