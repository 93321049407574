import React from 'react'
import PageLayouts from '../../layouts/PageLayouts'
import MetaData from '../../layouts/MetaData'

const DenetimKurulu = () => {
    const kuruluUye = [
        {
          fullname: "Ömer AYDIN",
          role: "Denetim Kurulu Başkanı",
          img: "/img/uyeler/omer-aydin.jpg",
        },
        {
          fullname: "Sametcan KARAMAN",
          role: "Denetim Kurulu Üyesi",
          img: "/img/uyeler/samet-can-karaman.jpg",
        },
        {
          fullname: "Yılmaz SEYİDOĞLU",
          role: "Genel Merkez Sözcüsü",
          img: "/img/bayrak-logo.jpeg",
        }
      ];
  return (
    <PageLayouts>
      <MetaData title={"Denetim Kurulu"} />
      <div id="content-dinamik-title">
        <div id="content-title-type" style={{ width: "60%" }}>
          Denetim Kurulu
        </div>
      </div>

      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="">
          <div className="baskanlik-anasayfa-alt-haber ">
            <div className="row gap-5 d-flex justify-content-center align-items-center">
              {kuruluUye?.map((uye) => (
                <div
                  className="col-md-3 border rounded-2"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ marginBottom: "20px" }}
                >
                  <img src={uye.img} alt="" className="kurul-card" />
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <h6 className="p-1 poppins-medium" style={{ color: "#1f1f1f", fontSize: "10px", marginTop: "15px"}}>{uye.fullname}</h6>
                    <span className="p-1 mb-3" style={{color: "#5c5c5c", fontSize: "12px"}}>{uye.role}</span>
                  </div>
                  {/* <ModalComp /> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  )
}

export default DenetimKurulu