import React from "react";
import PageLayouts from "../layouts/PageLayouts";
import { useGetNewsDetailsQuery, useGetNewsQuery } from "../redux/api/newsApi";
import { useParams } from "react-router-dom";
import MetaData from "../layouts/MetaData";

const NewsDetails = () => {
  const { slug } = useParams();
  const { data: news, isLoading, error } = useGetNewsDetailsQuery(slug);

  return (
    <PageLayouts>
      <MetaData title={`${news?.news?.title}`} />
      <div id="content-dinamik-title">
        <div id="content-title-type">
          <a style={{ color: "#c4b084" }}>{news?.news?.title}</a>
        </div>
        <div id="content-title-hepsi"></div>
      </div>
      <div id="detay-content" style={{ width: "100%" }}>
        <div className="blog-media">
          <img
            className="HaberResimYukleme radius5 img-fluid"
            data-resim-yolu=""
            loading="lazy"
            style={{ width: "100%" }}
            src={news?.news?.image?.url}
          />
        </div>
        <div className="blog-content">
          <div id="text-description">
            <div className="bright-text">
              <div id="haber-detay-baslik">
                <div
                  id="haber-detay-saat"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    id="haber-detay-saat-dakika"
                    style={{ display: "inline-block" }}
                  >
                    {new Date(news?.news?.createdAt).toLocaleDateString(
                      "TR-tr",
                      {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      }
                    )}
                  </div>

                  <div className="mobile-social-media">
                    <a
                      className="a-facebook"
                      target="_blank"
                      href="https://www.facebook.com/tuglar/"
                    >
                      <img src="/images/yeni_tasarim/renk/Icon_facebook.png" />
                    </a>
                    <a
                      className="a-facebook"
                      target="_blank"
                      href="https://www.instagram.com/turanocaklarii/"
                    >
                      <img src="/images/yeni_tasarim/renk/Icon_instagram.png" />
                    </a>
                    <a
                      className="a-facebook"
                      target="_blank"
                      href="https://x.com/turanocaklarii"
                    >
                      <img src="/images/yeni_tasarim/renk/Icon_twitter.png" />
                    </a>
                  </div>
                </div>
                { news?.news?.title }
              </div>
              <div id="haber-detay-aciklama" dangerouslySetInnerHTML={{ __html: news?.news?.content }} />
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  );
};

export default NewsDetails;
