import React, { useEffect, useState } from "react";
import { useRegisterMutation } from "../../redux/api/authApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import cities from "../../../constants/cities.json";

const Register = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    birthdate: "",
    bloodyType: "",
    city: "",
    district: "",
    address: "",
    job: "",
    phoneNumber: "",
  });
  const [selectedCity, setSelectedCity] = useState("");
  const {
    firstname,
    lastname,
    email,
    password,
    birthdate,
    bloodyType,
    city,
    district,
    address,
    job,
    phoneNumber,
  } = data;

  const [register, { error, isSuccess }] = useRegisterMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    register(data);
  };

  const onChangeCity = (e) => {
    setData({ ...data, city: e.target.value });
    const filteredData = cities?.data?.filter(
      (il) =>
        il.il_adi === e.target.value
    );
    setSelectedCity(filteredData[0]);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/giris");
    }
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [error, isSuccess, selectedCity]);


  return (
    <div className="container">
      <div className="row">
        <div
          className="d-flex justify-content-center align-items-center bg-light"
          style={{ height: "100%", marginTop: "50px" }}
        >
          <div
            className="card p-4 shadow-sm"
            style={{ width: "100%", maxWidth: "500px" }}
          >
            <h2 className="text-center mb-4">Üye Ol</h2>
            <form onSubmit={handleSubmit}>
              <div className="d-flex gap-2">
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="firstname" className="form-label">
                      Ad:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstname"
                      placeholder="Adınızı girin"
                      value={firstname}
                      onChange={(e) =>
                        setData({ ...data, firstname: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="lastname" className="form-label">
                      Soyad:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastname"
                      placeholder="Soyadınızı girin"
                      value={lastname}
                      onChange={(e) =>
                        setData({ ...data, lastname: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2">
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      E-Posta:
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="E-Postanızı girin"
                      value={email}
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Şifre:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Şifrenizi girin"
                      value={password}
                      onChange={(e) =>
                        setData({ ...data, password: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2">
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">
                      Telefon Numarası:
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phoneNumber"
                      placeholder="Numaranızı giriniz"
                      value={phoneNumber}
                      onChange={(e) =>
                        setData({ ...data, phoneNumber: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="job" className="form-label">
                      Mezuniyet Mesleği:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="job"
                      placeholder="Mesleğinizi girin"
                      value={job}
                      onChange={(e) =>
                        setData({ ...data, job: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="birthdate" className="form-label">
                    Doğum Tarihi
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="birthdate"
                    value={birthdate}
                    onChange={(e) =>
                      setData({ ...data, birthdate: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="mb-3">
                  <span className="">Kan Grubu</span>
                  <select
                    name="bloodyType"
                    value={bloodyType}
                    onChange={(e) =>
                      setData({ ...data, bloodyType: e.target.value })
                    }
                    className="form-control"
                  >
                    <option selected>Bir kan grubu seç</option>
                    <option value="0-">0-</option>
                    <option value="0+">0+</option>
                    <option value="A-">A-</option>
                    <option value="A+">A+</option>
                    <option value="B-">B-</option>
                    <option value="B+">B+</option>
                    <option value="AB-">AB-</option>
                    <option value="AB+">AB+</option>
                  </select>
                </div>
                <div className="mb-3">
                  <span className="">İl</span>
                  <select
                    name="city"
                    value={city}
                    onChange={(e) => onChangeCity(e)}
                    className="form-control"
                  >
                    <option selected>Bir şehir seç</option>
                    {cities?.data?.map((city) => (
                      <option value={city?.il_adi}>
                        {city?.il_adi}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedCity !== "" && (
                  <div className="mb-3">
                    <span className="">İlçe</span>
                    <select
                      name="district"
                      value={district}
                      onChange={(e) =>
                        setData({ ...data, district: e.target.value })
                      }
                      className="form-control"
                    >
                      <option selected>Bir ilçe seç</option>
                      {selectedCity?.ilceler?.map((d) => (
                        <option value={d?.ilce_adi}>
                          {d?.ilce_adi}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="mb-5">
                  <span className="">Açık Adres</span>
                  <textarea
                    name="address"
                    value={address}
                    placeholder="Adres giriniz"
                    onChange={(e) =>
                      setData({ ...data, address: e.target.value })
                    }
                    className="form-control"
                  />
                </div>

                <div className="mb-3">
                  <button
                    type="submit"
                    className="btn w-100 text-white"
                    style={{ backgroundColor: "#39608B" }}
                  >
                    Üye Ol
                  </button>
                </div>
                <div className="mt-3 text-center">
                  <a
                    href="/giris"
                    className="text-decoration-none"
                    style={{ color: "#39608B" }}
                  >
                    Hesabın Mı Var ?
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
