import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from "../../layouts/MetaData";

const Ozgecmis = () => {
  return (
    <PageLayouts>
      <MetaData title={"Özgeçmiş"} />
      <div id="content-dinamik-title">
        <div id="content-title-type" style={{ width: "60%" }}>
          Abdulkadir KAPLAN
        </div>
        <div className="baskanlik-header">ÖZGEÇMİŞ</div>
      </div>

      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="baskanlik-content">
          <div className="baskanlik-baskan-ozgecmis">
            <div style={{ float: "left", width: "30%" }}>
              <img
                style={{ width: "100%", paddingRight: "5%" }}
                src="/img/kadir1-1.png"
              />
            </div>
            <div style={{ textAlign: "justify" }}>
              Abdulkadir Kaplan, 1993 yılında Kayseri’nin Kocasinan ilçesinde
              dünyaya gelmiş, erken yaşlardan itibaren vatanına ve milletine
              olan bağlılığı ile tanınmıştır. İlk ve orta öğrenimini İstanbul’un
              Bağcılar ilçesinde tamamlayarak, gençlik yıllarında Türkiye’nin
              geleceği adına aktif rol almaya başlamıştır. <br />
              <br /> Eğitim hayatına Bakırköy Anadolu İmam Hatip Lisesi’nde
              devam eden Kaplan, burada ideallerine adım adım yaklaşarak cemiyet
              çalışmalarına olan ilgisini artırmıştır. Bağcılar Güneşli Ülkü
              Ocakları’nda ilçe teşkilatında görev aldıktan sonra, Saadet
              Partisi’nde Bakırköy Gençlik Kolları Başkanlığı görevini
              üstlenmiştir.
              <br />
              <br /> İmam Hatip Lisesi mezuniyetinin ardından, siyasi ve sosyal
              yaşama etkili bir şekilde katkıda bulunmak üzere Bakırköy Alperen
              Ocakları İlçe Başkanlığı ve Bakırköy Büyük Birlik Partisi İlçe
              Başkan Yardımcılığı görevlerini 2019 yılına kadar sürdürmüştür.
              Aynı zamanda, 2018 yılında Nizam-ı Çeri Ocakları Eğitim ve Kültür
              Derneği’nin kuruculuğunu üstlenerek, gençlerin kültürel ve
              eğitimsel gelişimine katkı sağlamıştır.
              <br />
              <br /> Abdulkadir Kaplan, Türkiye’nin sınırlarını aşarak,
              Ortadoğu’nun birçok ülkesinde Türkiye Cumhuriyeti devletinin
              çıkarlarına yönelik aktif faaliyetlerde bulunmuş, 2021-2022
              yılları arasında Milli Beka Hareketi Gençlik Kolları Genel
              Başkanlığı MKYK görevlerinde öncü bir rol üstlenmiştir.
              <br />
              <br /> Aynı zamanda, 2022-2023 yılları arasında İçkerya Çeçen
              Cumhuriyeti Türkiye Fahri Konsolosu olarak atanmıştır. 2022
              yılında, Türkiye’nin birçok il ve ilçesinde büyük bir heyecanla
              teşkilatlanma çalışmalarını yürüterek, Avrupa, Asya, Ortadoğu ve
              Balkanlar’da Turan Ocaklarının güçlenmesine büyük katkılarda
              bulunmuştur.
              <br />
              <br /> Abdulkadir Kaplan, sadece gençlik ve kültür alanında değil,
              aynı zamanda denizcilik alanında da nitelikli bir eğitim almıştır.
              Bilimsel Denizcilik Yüksek Okulu’nda Yat Kaptanlığı bölümünü
              başarıyla tamamlamış ve bu alandaki bilgi ve becerilerini
              ülkesinin hizmetine sunmaya kararlı bir şekilde devam etmektedir.
              Ayrıca, İngilizce dili konusunda da yetkinlik göstermektedir.
              <br />
              <br /> Abdulkadir Kaplan, ülkesine ve milletine adanmış bir ruhla,
              yaşadığı zorluklara karşın vazgeçmeden, Türk gençliğinin eğitim,
              kültür ve geleceğe yönelik umutlarını yüceltmek için var gücüyle
              çalışmaya devam etmektedir.
              <br />
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  );
};

export default Ozgecmis;
