import React, { useRef, useState } from "react";
import MetaData from "../layouts/MetaData";
import { useGetNewsQuery } from "../redux/api/newsApi";

const Home = () => {
  const { data: news, isLoading, error } = useGetNewsQuery();
  const haberlLinkleri = [
    {
      text: "VAKIF BAŞKANI",
      url: "haberAlaniMeclisBaskani",
    },
    {
      text: "HALK",
      url: "haberAlaniYasama",
    },
    {
      text: "TURAN OCAKLARI",
      url: "haberAlaniKomisyon",
    },
    {
      text: "ETKİNLİKLER",
      url: "haberAlaniMilletvekilleri",
    },
  ];

  const carouselNumberList = () => {
    for (let i = 0; i < news?.news?.length; i++) {}
  };

  const [activeHaberTuru, setActiveHaberTuru] = useState(
    "haberAlaniMeclisBaskani"
  );

  const AnasayfaHaberGetir = (haberTuru) => {
    setActiveHaberTuru(haberTuru);
  };

  return (
    <div>
      <MetaData title={"Anasayfa"} />
      <div id="anasayfa-banner-area">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-8 col-md-12">
              <div
                id="carouselAnasayfaSlider"
                data-bs-ride="carousel"
                className="carousel slide"
              >
                <div id="carousel-indicators-holder">
                  <div id="carousel-indicators-holder-left">
                    <ol className="carousel-indicators">
                      <li
                        data-bs-target="#carouselAnasayfaSlider"
                        className="active"
                        aria-current="true"
                        data-bs-slide-to="0"
                      >
                        1
                      </li>
                      {news?.news?.slice(1, 10)?.map((item, index) => (
                        <li
                          data-bs-target="#carouselAnasayfaSlider"
                          className=""
                          aria-current=""
                          data-bs-slide-to={index + 1}
                        >
                          {index + 2}
                        </li>
                      ))}
                    </ol>
                  </div>
                  <div id="carousel-indicators-holder-right">
                    <a href="https://turanocaklari.com/">HEPSİ </a>
                    <img
                      style={{ width: "8%", marginBottom: "5px" }}
                      src="/images/yeni_tasarim/WhiteRightArrowIcon.png"
                    />
                  </div>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <a href={`/haber/${news?.news[0]?.slug}`}>
                      <img src={news?.news[0]?.image?.url} />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          {news?.news[0]?.title}
                        </span>
                      </div>
                    </a>
                  </div>

                  {news?.news?.slice(1, 10)?.map((item) => (
                    <div className="carousel-item">
                      <a href={`/haber/${item?.slug}`}>
                        <img src={item?.image?.url} />

                        <div className="carousel-caption d-md-block">
                          <span className="carousel-baslik">{item?.title}</span>
                        </div>
                      </a>
                    </div>
                  ))}

                  {/* <div className="carousel-item">
                    <a href="/Haber/narin.html">
                      <img src="/img/haber/narin2.png" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          Narin Güran Olayı İçin Taziye Mesajı
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="/Haber/bozkurt.html">
                      <img src="/img/haber/bozkurt.JPG" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          Bozkurt İşareti: Türklük ve Birlik Simgesi
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="/Haber/millimac.html">
                      <img src="/img/haber/a-milli-takim.jpg" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          Türkiye Milli Takımının 2024 UEFA Başarıları
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="/Haber/bayram.html">
                      <img src="/img/haber/bayram.jpg" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          Başkan'ın Kurban Bayramı Mesajı
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="/Haber/filistin.html">
                      <img src="/img/haber/filistin2.JPG" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          Basın Açıklaması: Refah'taki Katliamı Şiddetle
                          Kınıyoruz
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="/Haber/Detay6.html">
                      <img src="/img/haber/filistin.jpg" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          Filistin Halkına Yapılan Zulmü Kınıyoruz
                        </span>
                      </div>
                    </a>
                  </div>

                  <div className="carousel-item">
                    <a href="/Haber/Uygur_Turkleri.html">
                      <img src="/img/haber/uygur.jpg" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          Uygur Türklerine Yapılan Şiddeti Kınama Bildirisi
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="/Haber/Detay5.html">
                      <img src="/img/haber/reisi.JPG" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          İran Cumhurbaşkanı İbrahim Reisi’nin Anısına Saygıyla{" "}
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="/Haber/hayvanhaklari.html">
                      <img src="/img/haber/hayvan.JPG" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          Hayvan Haklarına Saygı: Şiddetin Karşısında Birlik
                          Olalım{" "}
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="/Haber/Detay1.html">
                      <img src="/img/to1-1.png" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          Terörün Gölgesinde Yıllardır Devam Eden Bir Mücadele!{" "}
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="carousel-item ">
                    <a href="/Haber/Detay3.html">
                      <img src="img/to2-1.png" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          Regaip Kandili'miz Kutlu Olsun
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="carousel-item ">
                    <a href="/Haber/Detay2.html">
                      <img src="/img/to1-1.png" />

                      <div className="carousel-caption d-md-block">
                        <span className="carousel-baslik">
                          ÇAĞIN MANEVİ HASTALIKLARINA TEK ÇARE; Turan
                          Ocaklarında!
                        </span>
                      </div>
                    </a>
                  </div> */}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselAnasayfaSlider"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselAnasayfaSlider"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div id="anasayfa-social-area">
                <a target="_blank" href="https://www.facebook.com/tuglar/">
                  <div>
                    <img src="/images/yeni_tasarim/Icon_facebook.png" />
                  </div>
                </a>

                <a target="_blank" href="https://twitter.com/turanocaklarii">
                  <div>
                    <img src="/images/yeni_tasarim/Icon_twitter.png" />
                  </div>
                </a>

                <a
                  id="anasayfa-social-link-instagram"
                  target="_blank"
                  href="https://www.instagram.com/turanocaklarii/"
                >
                  <div>
                    <img src="/images/yeni_tasarim/Icon_instagram.png" />
                  </div>
                </a>

                <a
                  target="_blank"
                  id="anasayfa-social-link-youtube"
                  href="https://www.youtube.com/"
                >
                  <div>
                    <img src="/images/yeni_tasarim/Icon_youtube.png" />
                  </div>
                </a>
              </div>

              <div id="tbmmde-bugun">
                <div id="tbmmde-bugun-baslik-alani">
                  <div id="tbmmde-bugun-tarih-alani">
                    <span id="tbmmde-bugun-ay-yil">
                      <b>
                        {new Date(Date.now()).toLocaleDateString("TR-tr", {
                          day: "numeric",
                          month: "long",
                        })}{" "}
                      </b>
                      <span id="tbmmde-bugun-yil">
                        <b>
                          {new Date(Date.now()).toLocaleDateString("TR-tr", {
                            year: "numeric",
                          })}
                        </b>
                      </span>
                    </span>
                    <span id="tbmmde-bugun-gun-adi">
                      Turan Ocakları Haber Sitesi
                    </span>
                  </div>

                  <div id="tbmmde-bugun-splitter"></div>

                  <div id="tbmmde-bugun-hepsi-link">
                    <a target="_blank" href="https://www.turanocaklari.com/">
                      İncele{" "}
                      <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                    </a>
                  </div>
                </div>

                <div
                  id="tbmmde-bugun-satirlarin-alani"
                  style={{ overflow: "auto", maxHeight: "280px" }}
                >
                  {news?.news?.slice(-8)?.map((item) => (
                    <a href={`/haber/${item?.slug}`} className="" >
                      <div className="pb-3 pt-2" style={{borderBottom: "1px solid #39608B"}}>
                      <p className="" style={{color: "#555555", fontSize: "20px"}}>{item?.title}</p>
                      <span style={{color: "#555555"}}>{new Date(item?.createdAt).toLocaleDateString("TR-tr", {day: "numeric", month: "long", year: "numeric"})}</span>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-0">
            <div className="col-md-12">
              <div id="anasayfa-link-bar">
                <a className="anasayfa-link-bar-link" href="/yakinda">
                  <div>
                    <img src="/images/yeni_tasarim/icon_parlamentolar_arasi_iliskiler.png" />
                  </div>
                  <span>
                    HALKLA
                    <br />
                    İLİŞKİLER
                  </span>
                </a>

                <a
                  className="anasayfa-link-bar-link anasayfa-link-bar-link-kutuphane"
                  href="https://www.turanocaklari.com"
                >
                  <div>
                    <img src="/images/yeni_tasarim/icon_kutuphane.png" />
                  </div>
                  <span>
                    HABERLER VE <br />
                    BÜLTEN
                  </span>
                </a>

                <a
                  className="anasayfa-link-bar-link anasayfa-link-bar-link-anayasa"
                  href="tuzuk/tuzuk"
                >
                  <div>
                    <img src="/images/yeni_tasarim/icon_anayasa.png" />
                  </div>
                  <span>
                    <br />
                    TÜZÜK
                  </span>
                </a>

                <a
                  className="anasayfa-link-bar-link anasayfa-link-bar-link-kanun"
                  href="/yakinda"
                >
                  <div>
                    <img src="/images/yeni_tasarim/icon_kanun_ve_kararlar.png" />
                  </div>
                  <span>
                    KARARLAR
                    <br />
                    BİLGİ SİSTEMİ
                  </span>
                </a>

                <a
                  className="anasayfa-link-bar-link anasayfa-link-bar-link-edilekce"
                  href="baskan/iletisim"
                >
                  <div>
                    <img src="/images/yeni_tasarim/icon_edilekce.png" />
                  </div>
                  <span>İLETİŞİM</span>
                </a>

                <a
                  className="anasayfa-link-bar-link anasayfa-link-bar-link-bilgiedinme"
                  href="/tuzuk/hakkimizda"
                >
                  <div>
                    <img src="/images/yeni_tasarim/icon_bilgi_edinme.png" />
                  </div>
                  <span>HAKKIMIZDA</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="anasayfa-haberler-area">
        <div className="container">
          <div className="row">
            <div id="anasayfa-haberler-bar">
              <div id="anasayfa-haberler-bar-baslik">HABERLER</div>

              <div id="anasayfa-haberler-bar-hepsi-link">
                <a href="https://turanocaklari.com/">
                  HEPSİ{" "}
                  <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />{" "}
                </a>
              </div>
            </div>

            <div id="anasayfa-haberler-menu">
              {haberlLinkleri?.map((item) => (
                <a
                  id="link-haberAlaniMeclisBaskani"
                  className={`${item?.url === activeHaberTuru && "active "}`}
                  style={{ transition: "ease .25s all", cursor: "pointer" }}
                  onClick={() => AnasayfaHaberGetir(item?.url)}
                >
                  <div>{item?.text}</div>
                </a>
              ))}
            </div>

            <div id="anasayfa-haberler-active-area-holder">
              <div id="anasayfa-haberler-active-area">
                <div
                  style={{
                    height: `${
                      activeHaberTuru === "haberAlaniMeclisBaskani"
                        ? "100%"
                        : "0"
                    }`,
                    opacity: `${
                      activeHaberTuru === "haberAlaniMeclisBaskani" ? 1 : 0
                    }`,
                    transition: "opacity 0.2s",
                  }}
                >
                  <div className="row">
                    {news?.news?.slice(-4)?.map((item) => (
                      <div className="col-md-3">
                        <a href={`/haber/${item?.slug}`}>
                          <div
                            className="arh ImageLazyLoader"
                            data-resim-yolu=""
                            style={{
                              backgroundImage: `url('${item?.image?.url}')`,
                            }}
                          ></div>
                          <div className="art">
                            {new Date(item?.createdAt).toLocaleDateString(
                              "TR-tr",
                              { day: "numeric", month: "long", year: "numeric" }
                            )}
                          </div>
                          <div className="arb">{item?.title}</div>
                          <div className="ard">{item?.subtitle}</div>

                          <div className="art-mobile">
                            {new Date(item?.createdAt).toLocaleDateString(
                              "TR-tr",
                              { day: "numeric", month: "long", year: "numeric" }
                            )}
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  id=""
                  style={{
                    height: `${
                      activeHaberTuru === "haberAlaniYasama" ? "100%" : "0"
                    }`,
                    opacity: `${
                      activeHaberTuru === "haberAlaniYasama" ? 1 : 0
                    }`,
                    transition: "opacity 0.2s",
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <a href="/yakinda">
                        <div
                          className="arh ImageLazyLoader"
                          data-resim-yolu=""
                          style={{
                            backgroundImage: "url('/img/yakinda.png')",
                            width: "100%",
                          }}
                        ></div>
                      </a>
                    </div>
                  </div>
                </div>

                {activeHaberTuru === "haberAlaniKomisyon" && (
                  <div
                    id=""
                    style={{
                      height: `${
                        activeHaberTuru === "haberAlaniKomisyon" ? "100%" : "0"
                      }`,
                      opacity: `${
                        activeHaberTuru === "haberAlaniKomisyon" ? 1 : 0
                      }`,
                      transition: "opacity 0.2s",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <a href="/yakinda">
                          <div
                            className="arh ImageLazyLoader"
                            data-resim-yolu=""
                            style={{
                              backgroundImage: "url('img/yakinda.png')",
                              width: "100%",
                            }}
                          ></div>
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                {activeHaberTuru === "haberAlaniMilletvekilleri" && (
                  <div
                    id=""
                    style={{
                      height: `${
                        activeHaberTuru === "haberAlaniMilletvekilleri"
                          ? "100%"
                          : "0"
                      }`,
                      opacity: `${
                        activeHaberTuru === "haberAlaniMilletvekilleri" ? 1 : 0
                      }`,
                      transition: "opacity 0.2s",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <a href="/yakinda">
                          <div
                            className="arh ImageLazyLoader"
                            data-resim-yolu=""
                            style={{
                              backgroundImage: "url('/img/girisimcilik.jpeg')",
                            }}
                          ></div>
                          <div className="art">2024-04-04 - 16:48</div>
                          <div className="arb">Yaz Girişimcilik Eğitimi</div>
                          <div className="ard">
                            Yaz Girişimcilik Eğitimi, iş kurma hayalleri olan
                            herkes için tasarlanmış kapsamlı bir programdır. Bu
                            eğitimde...
                          </div>
                          <div className="art-mobile">2024-04-04 - 16:48</div>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="/yakinda">
                          <div
                            className="arh ImageLazyLoader"
                            data-resim-yolu=""
                            style={{
                              backgroundImage: "url('/img/yazilim.jpeg')",
                            }}
                          ></div>
                          <div className="art">2024-04-04 - 14:26</div>
                          <div className="arb">Yazılım Eğitimi</div>
                          <div className="ard">
                            Yazılım Eğitimi, temel yazılım bilgisine sahip
                            olmayanlar için ideal bir fırsat sunuyor. Bu
                            eğitimde, Python, JavaScript gibi popüler
                            programlama...
                          </div>
                          <div className="art-mobile">2024-04-04 - 14:26</div>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="/yakinda">
                          <div
                            className="arh ImageLazyLoader"
                            data-resim-yolu=""
                            style={{
                              backgroundImage: "url('/img/grafik.jpeg')",
                            }}
                          ></div>
                          <div className="art">2024-04-04 - 11:42</div>
                          <div className="arb">Tasarım Eğitimi</div>
                          <div className="ard">
                            Tasarım Eğitimi, grafik tasarım ve kullanıcı arayüzü
                            tasarımı gibi konuları içeren interaktif bir
                            programdır. Bu eğitimde, Adobe Creative Suite
                            gibi...
                          </div>
                          <div className="art-mobile">2024-04-04 - 11:42</div>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="anasayfa-duyurular-area">
        <div className="container">
          <div className="row">
            <div className="col-md-3" style={{ padding: "0px" }}>
              <div id="anasayfa-etkinlikler">
                <div className="duyuru-baslik-holder">
                  <div className="duyuru-baslik">ETKİNLİKLER</div>
                </div>

                <div className="duyurular-content">
                  <a href="/yakinda">
                    <img src="/img/etkinliklerimiz.png" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-3" style={{ padding: "0px" }}>
              <div id="anasayfa-fotograflarla-tbmm">
                <div className="duyuru-baslik-holder">
                  <div className="duyuru-baslik">FOTOĞRAFLARLA BİZ</div>
                </div>
                <div className="duyurular-content">
                  <a href="/yakinda">
                    <img src="img/fotograflar-biz.png" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-3" style={{ padding: "0px" }}>
              <div id="anasayfa-sanal-tur-holder">
                <div className="duyuru-baslik-holder">
                  <div className="duyuru-baslik">BASINDA BİZ</div>
                </div>
                <div className="duyurular-content">
                  <a href="/yakinda">
                    <img src="img/basinda-biz.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
