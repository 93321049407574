import React from "react";
import RightSidebar from "../../layouts/RightSidebar";

const Bayram = () => {
  return (
    <div class="page-content">
      <div class="container  g-0">
        <div class="row g-0">
          <div class="col-lg-8 col-md-12">
            <div id="content-dinamik-title">
              <div id="content-title-type">
                <a style={{color:"#c4b084"}} href="https://turanocaklari.com/">
                  HABERLER
                </a>
              </div>
              <div id="content-title-hepsi"></div>
            </div>

            <div id="detay-content" style={{width:"100%"}}>
              <div class="blog-media">
                <img
                  class="HaberResimYukleme"
                  data-resim-yolu=""
                  loading="lazy"
                  style={{width:"100%"}}
                  src="/img/haber/bayram.jpg"
                />
              </div>
              <div class="blog-content">
                <div id="text-description">
                  <div class="bright-text">
                    <div id="haber-detay-baslik">
                      <div
                        id="haber-detay-saat"
                        style={{display:"flex", justifyContent:"space-between"}}
                      >
                        <div
                          id="haber-detay-saat-dakika"
                          style={{display:"inline-block"}}
                        >17 Haziran 2024</div>

                        <div class="mobile-social-media">
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://www.facebook.com/tuglar/"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_facebook.png" />
                          </a>
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://www.instagram.com/turanocaklarii/"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_instagram.png" />
                          </a>
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://x.com/turanocaklarii"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_twitter.png" />
                          </a>
                        </div>
                      </div>
                      Başkan'ın Kurban Bayramı Mesajı
                    </div>
                    <div id="haber-detay-golge">
                      <div id="haber-detay-ozet">
                        Sevgili Üyelerimiz ve Değerli Hemşehrilerimiz,
                      </div>
                    </div>

                    <div id="haber-detay-aciklama">
                      <p>
                        Mübarek Kurban Bayramı'nı coşku ve mutluluk içinde
                        karşılamanın huzurunu yaşıyoruz. Bu özel günler, birlik
                        ve beraberliğimizin, kardeşlik ve dayanışmamızın en
                        güzel örneklerini sergilediğimiz anlardır. Kurban
                        Bayramı, bizlere paylaşmanın, yardımlaşmanın ve sevginin
                        ne denli önemli olduğunu bir kez daha hatırlatır.
                      </p>
                      <p>
                        Bu vesileyle, tüm üyelerimizin ve milletimizin Kurban
                        Bayramı'nı en içten dileklerimle kutluyor, bayramın
                        sizlere ve sevdiklerinize sağlık, mutluluk ve huzur
                        getirmesini diliyorum. Rabbim, kesilen kurbanlarımızı
                        kabul etsin, niyetlerimizi makbul eylesin.
                      </p>
                      <p>
                        Bayram boyunca ziyaret edemediğimiz, gönlümüzden geçip
                        de yanlarında olamadığımız tüm dostlarımıza
                        selamlarımızı ve dualarımızı gönderiyor, herkesin
                        bayramını en kalbi duygularımla kutluyorum.
                      </p>
                      <p>Sevgi ve saygılarımla,</p>
                      <b>Abdulkadir Kaplan</b>
                      <br />
                      <b>Turan Ocakları Eğitim ve Kültür Vakfı Genel Başkanı</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bayram;
